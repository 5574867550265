import {
    collection,
    addDoc,
    getDocs,
    query,
    where,
    doc,
    orderBy,
    setDoc,
    increment,
    getDoc,
    deleteDoc,
    startAfter,
    limit
  } from 'firebase/firestore/lite'
  
  import { DB } from '../App'


export const addGift = giftDoc => {
  const collectionRef = collection(DB, `/Gift`)
  return addDoc(collectionRef, {
    ...giftDoc,
    creatd_at:+new Date()
  })
}

export async function getGifts () {
  const collectionRef = collection(DB, '/Gift');
  return getDocs(collectionRef); 
}

export async function deleteGiftById(giftId){
  if(!giftId)throw new Error('expected a gift id');
  const collectionRef = collection(DB, `/Gift`)
  const giftDocRef = doc(collectionRef, giftId);
  const deletedDoc = await deleteDoc(giftDocRef);
}




