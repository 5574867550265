import React, { useState, useEffect, useContext, useLayoutEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { getAuth, signOut, signInWithEmailAndPassword, setPersistence, browserSessionPersistence } from "firebase/auth";

import useAuth from "../../hooks/useAuth"
import { userStateContext } from '../../context/userStateContext';
import { useEnterKeyPress } from '../../hooks/useEnterKeyPress';
import "./login.scss";


const LoginForm = () => {
  const [loginInProcess, setLoginInProcess] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null)
  const auth = getAuth()
  const navigate = useNavigate();

  useEnterKeyPress(handleLogin);

  const { user } = useContext(userStateContext);

  useLayoutEffect(() => {
    console.log(user);
    if (user) {
      navigate('/')
    }
  }, [user]);



  async function handleLogin() {
    setLoginInProcess(true)
    setError(null);
    await setPersistence(auth, browserSessionPersistence)
    try {
      await signInWithEmailAndPassword(auth, email, password);
    } catch (error) {
      console.log(error.message)
      setError("Email ou mot de passe incorrect ");
    } finally {
      setLoginInProcess(false)
    }
  }

  return (
    <div className="login-form-container">
      <h2 className='brand'>Viola</h2>
      <h2 className='brand-subText'>Beauty Center</h2>
      <form>

        <div className="errorContaineer">
          {error && <p>{error}</p>}
        </div>

        <label htmlFor="email">Email:</label>

        <input type="email"
          id="email"
          name="email"
          value={email}
          required
          onChange={(e) => setEmail(e.target.value)}
        />

        <label htmlFor="password">Password:</label>

        <input type="password"
          id="password"
          name="password"
          required
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />

        <button type="button" onClick={handleLogin}>S'identifer</button>
      </form>
    </div>
  );
};



const Login = () => {
  return (
    <LoginForm />
  )
}

export default Login
