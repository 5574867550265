const userStateReducer = (state, action) => {
    switch (action.type) {
        case "LOGIN_SUCCESS": {
            console.log("payload", action.payload);
            return {
                ...state,
                loggedIn: true,
                user: action.payload
            };
        }
        case "LOGIN_ERROR": {
            return {
                loggedIn: false,
            };
        }
        case "LOGOUT": {
            return {
                ...state,
                loggedIn: false,
                user: null,

            };
        }
        default:
            return state;
    }
};



export default userStateReducer;