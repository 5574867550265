import { createContext, useReducer, useEffect } from "react";
import userStateReducer from "./userStateReducer";
// import firebase from "firebase/app";
import { firebase } from "../App";

import { getAuth, signOut, signInWithEmailAndPassword, setPersistence, browserSessionPersistence, onAuthStateChanged } from "firebase/auth";
import { useNavigate } from "react-router-dom";

const INITIAL_STATE = {
    loggedIn: false,
    user: null,
    error: null,
}

export const userStateContext = createContext(INITIAL_STATE)

export const UserStateContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(userStateReducer, INITIAL_STATE);
    const auth = getAuth();

    useEffect(() => {
        // Add Firebase auth state listener to track user changes
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                dispatch({ type: 'LOGIN_SUCCESS', payload: user })
            } else {
                dispatch({ type: 'LOGIN_ERROR' })
            }
        });

        // Clean up the auth state listener on unmount
        return () => unsubscribe();
    }, []);



    return (
        <userStateContext.Provider value={{ ...state, dispatch }}>
            {children}
        </userStateContext.Provider>
    )
} 