import './show.scss'
import Sidebar from '../../components/sidebar/Sidebar'
import Navbar from '../../components/navbar/Navbar'
import Chart from '../../components/chart/Chart'
import List from '../../components/table/Table'
import { CircularProgress } from '@mui/material'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { getMessageById, updateMessageStatusTo } from '../../service/Message'

// icons
import MessageIcon from '@mui/icons-material/Message'
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid'
import PersonIcon from '@mui/icons-material/Person'
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople'

const InboxMessage = () => {
  const [loading, setLoading] = useState(true)
  const [messageData, setMessageData] = useState({})
  const navigation = useNavigate()
  const { messageId } = useParams()

  useEffect(() => {
    async function fetchMessage (messageId) {
      setLoading(true)
      const message = await getMessageById(messageId)
      setMessageData(message.data())
      setLoading(false)
      // update the message status
      if (messageData.status == 'NEW') {
        console.log('updating message status')
        updateMessageStatusTo(messageId)
      } else {
        console.log()
      }
    }

    fetchMessage(messageId)
  }, [messageId])

  return (
    <div className='single'>
      <Sidebar />
      <div className='singleContainer'>
        <Navbar />

        <div
          style={{
            display: 'flex',
            paddingLeft: 20,
            paddingTop: 20,
            flexDirection: 'column'
          }}
        >
          <div className='message-header'>
            <h1>Message</h1>
          </div>
          {loading && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '120px'
              }}
            >
              <CircularProgress size={50} />
            </div>
          )}

          {!loading && (
            <div className='message-content'>
              <h3>
                <span className='left-ico'>
                  Subject
                  <EmojiPeopleIcon />
                </span>
                <span className='msg-val'>{messageData['name']}</span>
              </h3>
              <h3>
                <span className='left-ico'>
                  Téléphone
                  <PhoneAndroidIcon />
                </span>
                <span className='msg-val'>{messageData['phone']}</span>
              </h3>
              <div className='msgbox-container'>
                <MessageIcon />
                <p className='msg msg-val'>{messageData['message']}</p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default InboxMessage
