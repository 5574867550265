import { useState, useEffect } from 'react'
import './datatable.scss'
import { DataGrid } from '@mui/x-data-grid'
import { userRows, ClientColumns } from '../../datatablesource'
import { Link } from 'react-router-dom'
import {deleteClientById} from "../../service/user";
import { useClients, useRowsCount } from '../../hooks/client'

import DeleteIcon from '@mui/icons-material/Delete'

const ClientsDataTable = () => {
  const [options, setOptions] = useState({
    limit: 10,
    page: 0,
    lastVisible: null
  })

  const [cursor, setCursor] = useState({})
  const [clients, loading, error,reload] = useClients(options)
  const [loadingRowsCount, errorRowsCount, rowsCount] = useRowsCount()

  const pageSize = 10 // Number of items per page

  useEffect(() => {
    setCursor(prev => {
      return {
        ...prev,
        [options.page]: {
          lastVisible: clients[clients.length - 1]
        }
      }
    })
    console.log(cursor)
  }, [clients])

  const handleOnPageChange = page => {
    console.log(page, cursor[page - 1])
    if (page == 0) {
      setOptions(prev => {
        return {
          ...prev,
          page: 0,
          lastVisible: null
        }
      })
    } else {
      console.log(cursor)
      console.log(page)
      setOptions(prev => {
        if (prev.page < page) {
          return {
            ...prev,
            page,
            lastVisible: clients[clients.length - 1]
          }
        } else {
          return {
            ...prev,
            page,
            lastVisible: cursor[page - 1]['lastVisible']
          }
        }
      })
    }
  }


  const handleDeleteUser = async (userId) => {
    await deleteClientById(userId);
    reload();
    // reload list of users;
  }

  const actionColumn = [
    {
      field: 'action',
      headerName: 'Action',
      width: 400,
      renderCell: params => {
        const viewLink = `/users/${params.row.id}`
        return (
          <div className='cellAction'>
            <Link to={viewLink} style={{ textDecoration: 'none' }}>
              <div className='viewButton'>Visualiser</div>
            </Link>

            <button
            className="deleteButton"
              onClick={() => {
                handleDeleteUser(params.row.id)
              }}
            >
              <DeleteIcon />
            </button>
          </div>
        )
      }
    }
  ]

  return (
    <div className='datatable'>
      <div className='datatableTitle'>
        Liste de clients
        <Link to='/users/new' className='link'>
          Nouveau Client
        </Link>
      </div>

      <DataGrid
        loading={loading}
        rows={clients}
        columns={ClientColumns.concat(actionColumn)}
        pagination
        pageSize={pageSize}
        rowCount={rowsCount}
        paginationMode='server'
        onPageChange={handleOnPageChange}
        className='datagrid'
      />
    </div>
  )
}

export default ClientsDataTable
