import React from 'react'
import './list.scss'
import Sidebar from '../../../components/sidebar/Sidebar'
import Navbar from '../../../components/navbar/Navbar'
import ClientsDataTable from '../../../components/datatable/ClientsDataTable'


const ListClients = () => {
  return (
    <div className='list'>
      <Sidebar />
      <div className='listContainer'>
        <Navbar />
        <ClientsDataTable />
      </div>
    </div>
  )
}

export default ListClients
