import { useEffect } from 'react';

export function useEnterKeyPress(callback) {
  useEffect(() => {
    function handleKeyPress(event) {
      if (event.key === 'Enter') {
        callback();
      }
    }

    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [callback]);
}


