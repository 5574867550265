import "./new.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { Alarm, DriveFolderUploadOutlined } from "@mui/icons-material";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { userStateContext } from "../../context/userStateContext";
import { addServiceCategory } from "../../service/category";


const NewCategory = () => {
  const [categoryCount, setCategorieCount] = useState(1);
  const [categories, setCategories] = useState(['Nouvelle Category']);
  const navigate = useNavigate();

  const { user } = useContext(userStateContext);

  useEffect(() => {
    if (null == user || false == user.loggedIn) {
      navigate('/login')
    }
  }, [user]);

  const incrementCategoryCount = () => {
    setCategorieCount((count) => {
      return count + 1
    })
  }

  const editInput = (index) => {
    return function (e) {
      console.log(e.target.value);
      const oldArray = [...categories]
      oldArray[index] = e.target.value
      setCategories(oldArray);
    }
  }

  const clearInputs = () => {
    setCategorieCount(1);
    setCategories(['Nouvelle Category']);
  }


  const addCategories = async (e) => {
    e.preventDefault();

    try {
      for (const element of categories) {
        if (element == "Nouvelle Category" || element == "" || element.trim() == "") {
          throw new Error("Element is empty")
        }
        await addServiceCategory(element);
      }
      clearInputs();
    } catch (error) {
      console.log(error);
    }
  }


  return (
    <div className="new">
      <Sidebar />

      <div className="newContainer">
        <Navbar />
        <div className='headerTitle'>
          <h1>Ajouter Une Nouvelle catégorie</h1>
        </div>

        <div className="formContainer">
          <div className="formActionsConatiner">
            <button className="anotherCategory" onClick={incrementCategoryCount}>Une Autre</button>
          </div>
          <form className="addNewCategory">
            {
              Array.from({ length: categoryCount }).map((_element, index) => {
                return (
                  <div key={index}>
                    <input type="text" name="categoryName" value={categories[index]} onChange={editInput(index)} />
                  </div>
                )
              })
            }
            <button type="button" onClick={addCategories}>Ajouter</button>
          </form>
        </div>
      </div>
    </div>
  )
}


const New = ({ inputs, title }) => {
  const [file, setFile] = useState("");


  return (
    <div className="new">
      <Sidebar />

      <div className="newContainer">
        <Navbar />
        <div className="top">
          <h1>{title}</h1>
        </div>
        <div className="bottom">
          <div className="left">
            <img
              src={file ? URL.createObjectURL(file) : "https://www.icon-library.com/images/no-image-icon/no-image-icon-0.jpg"} alt="" />
          </div>
          <div className="right">
            <form>
              <div className="formInput">
                <label htmlFor="file">
                  Image : <DriveFolderUploadOutlined className="icon" />
                </label>
                <input
                  type="file"
                  id="file"
                  onChange={e => setFile(e.target.files[0])}
                  style={{ display: "none" }}
                />
              </div>
              {inputs.map((input) => (
                <div className="formInput" key={input.id}>
                  <label>{input.label}</label>
                  <input type={input.type} placeholder={input.placeholder} />
                </div>
              ))}
              <button>Send</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NewCategory