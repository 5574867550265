import './show.scss'
import Sidebar from '../../../components/sidebar/Sidebar'
import Navbar from '../../../components/navbar/Navbar'
import Chart from '../../../components/chart/Chart'
import List from '../../../components/table/Table'
import { CircularProgress } from '@mui/material'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import {
  getReservationById,
  updateReservationStatus
} from '../../../service/reservation'

// icons
import MessageIcon from '@mui/icons-material/Message'
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid'
import PersonIcon from '@mui/icons-material/Person'
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople'

const ShowReservationDetails = () => {

  const [loading, setLoading] = useState(true)
  const [reservationData, setReservationData] = useState({})
  const navigation = useNavigate()
  const { reservationId } = useParams()

  async function markReservationAs (updatedValue) {
    const updatedReservation = await updateReservationStatus(
      reservationId,
      updatedValue
    )
    setReservationData(prev => {
      return {
        ...prev,
        status: updatedValue
      }
    })
  }

  useEffect(() => {
    async function fetchReseravation () {
      setLoading(true)
      const reservation = await getReservationById(reservationId)
      setReservationData(reservation.data())
      console.log('here');
      console.log(reservationData)
      setLoading(false)
    }

    fetchReseravation()
  }, [reservationId])

  return (
    <div className='single'>
      <Sidebar />
      <div className='singleContainer'>
        <Navbar />

        <div
          style={{
            display: 'flex',
            paddingLeft: 20,
            paddingTop: 20,
            flexDirection: 'column'
          }}
        >
          <div className='reservation-header'>
            <h1>
              Détails Reservation |{' '}
              {reservationData['status'] == 'NEW' ? 'Nouvelle' : 'Traiteé'}
            </h1>
            <div>
              <span className="mark-as"> marqué comme </span>
              <button
                className='btn btn-left'
                disabled={reservationData['status'] == 'SEEN'}
                onClick={() => markReservationAs('SEEN')}
              >
                Vu
              </button>
              <button
                className='btn btn-new'
                disabled={reservationData['status'] == 'NEW'}
                onClick={() => markReservationAs('NEW')}
              >
                Nouvelle
              </button>
            </div>
          </div>

          {loading && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '120px'
              }}
            >
              <CircularProgress size={50} />
            </div>
          )}

          {!loading && (
            <>
              <div className='reservation-content'>
                <h2>
                  Pour le
                  <span> {reservationData['when']} </span>À
                  <span> {reservationData['at']} </span>
                </h2>
                <h2>Sujet du Réservation</h2>
                <ul>
                  {reservationData['for'].map((element, index) => {
                    return <li key={element['id']}>{element['serviceName'] || element['serviceCategory']  }</li>
                  })}
                </ul>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default ShowReservationDetails
