import {
  collection,
  addDoc,
  getDocs,
  query,
  where,
  doc,
  updateDoc,
  deleteDoc
} from 'firebase/firestore/lite'
import { DB } from '../App'

export const addService = doc => {
  return addDoc(collection(DB, `/Service`), doc)
}

export const getServicesByCategoryId = categoryId => {
  if (null === categoryId)
    return Promise.reject(new Error("category id can't be blank"))
  const servicesRef = collection(DB, `/Service`)
  const q = query(servicesRef, where('selectedCategoryId', '==', categoryId))
  return new Promise(async (resolve, reject) => {
    try {
      const servicesSnapshot = await getDocs(q)
      resolve(servicesSnapshot.docs)
    } catch (error) {
      console.error('Error fetching setvices by categoryId')
      console.log(error)
      reject(error)
    }
  })
}

/**
 * @description Adds Service
 * @returns Promise
 */

export const addServiceWithMultipleSessions = doc => {
  return addDoc(collection(DB, `/ServiceMultipleSessions`), doc)
}

export const getServicesWithMultipleSessions = () => {
  const servicesWithMultipleSessionsRef = collection(
    DB,
    `/ServiceMultipleSessions`
  )
  return getDocs(servicesWithMultipleSessionsRef)
}

export const getServicesWithOneSession = () => {
  const servicesRef = collection(DB, `/Service`)
  return getDocs(servicesRef)
}

const pickNameAndIdFromDoc = ({ serviceName, id }) => {
  return {
    serviceName,
    id
  }
}

const extractIDAndName = snapShot => {
  if (snapShot && snapShot.docs) {
    return snapShot.docs
      .map(doc => ({
        id: doc.id,
        ...doc.data()
      }))
      .map(pickNameAndIdFromDoc)
  }
}

export const getAllServices = async () => {
  const sericesWithOneSession = await getServicesWithOneSession()
  const servicesWithMultipleSessions = await getServicesWithMultipleSessions()
  return [
    ...extractIDAndName(sericesWithOneSession),
    ...extractIDAndName(servicesWithMultipleSessions)
  ]
}

export const getServicesWithSingleSession = async () => {
  const servicesWithOneSessionSnap = await getServicesWithOneSession()
  // const servicesWithMultipleSessionsSnapshot = await getServicesWithMultipleSessions();
  return servicesWithOneSessionSnap.docs.map(doc => ({ id: doc.id,...doc.data()}));
}


export const getSubscriptionServices = async () => {
  // const servicesWithOneSessionSnap = await getServicesWithOneSession()
  const servicesWithMultipleSessionsSnapshot = await getServicesWithMultipleSessions();
  return servicesWithMultipleSessionsSnapshot.docs.map(doc => ({ id: doc.id,...doc.data()}));
}




export const deleteServiceById = async (serviceId) => {
  if(!serviceId)throw new Error('expected a service id');
  const collectionRef = collection(DB, `/Service`)
  const serviceDocRef = doc(collectionRef, serviceId);
  const deletedDoc = await deleteDoc(serviceDocRef);
}


export const deleteSubscriptionById = async (subscriptionId) => {
  console.log('deleteSubscriptionById called');
  if(!subscriptionId)throw new Error('expected a subscription id');
  const collectionRef = collection(DB, `/ServiceMultipleSessions`)
  const serviceDocRef = doc(collectionRef, subscriptionId);
  const deletedDoc = await deleteDoc(serviceDocRef);
}


