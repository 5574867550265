import { useState, useEffect } from 'react'
import {getMessagesCount} from '../service/Message'

export const useMessagesCount = options => {
    const [rowsCount, setRowsCount] = useState(0);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
  
    useEffect(()=>{
      const fetchData = async () => {
        setLoading(true);
        const rowsCnt = await getMessagesCount();
  
        if(rowsCnt === -1){
          setError("Erreur lor's de la recuperation du nombre de reservations");
          setRowsCount(0);
          return;
        }
  
        setRowsCount(rowsCnt);
        setLoading(false);
        setError(false);
      }
      fetchData();
    },[])
    return [loading, error,rowsCount]
}