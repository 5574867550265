import { useState, useEffect } from 'react'
import { getReservations } from '../service/reservation'

export const useReservation = options => {
  const { page, lastVisible, elementsPerPage } = options
  const [reservations, setReservations] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)

  const fetchData = async () => {
    setLoading(true)
    setReservations([])

    try {
      let response = await getReservations({ lastVisible, elementsPerPage })
      // get data
      let data = response.map(client => {
        return {
          id: client.id,
          ...client.data()
        }
      })
      setReservations(data)
      console.log('GOT DATA');
      console.log(data);
    } catch (error) {
      console.log(error)
      setError(error)
      setReservations([])
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchData()
  }, [page])


  const reload = function(){
    console.log('relaod called');
    fetchData();
  }

  return [reservations, loading, error,reload]
}
