import { collection, addDoc, getDocs, query, where, doc, updateDoc,deleteDoc} from 'firebase/firestore/lite';
import { DB } from "../App";


export async function getCategories () {
    const collectionRef = collection(DB, '/ServiceCategory');
    return getDocs(collectionRef); 
}

export async function deleteCategoryById(categoryId){
    if(!categoryId)throw new Error('expected a categoryId id');
    const collectionRef = collection(DB, `/ServiceCategory`)
    const categoryDocRef = doc(collectionRef, categoryId);
    const deletedDoc = await deleteDoc(categoryDocRef);
}
  

export function addServiceCategory(categoryName) {
    const doc = {
        name: categoryName,
        createdAt: + new Date
    }

    return addDoc(collection(DB, `/ServiceCategory`), doc);
}

export async function getServiceCatogories() {
    const collectionRef = collection(DB, `/ServiceCategory`);
    return new Promise(async (resolve, reject) => {
        try {
            const ServiceCatogoriesSnapshot = await getDocs(collectionRef);
            const jobsList = ServiceCatogoriesSnapshot.docs.map(doc => {
                return {
                    id: doc.id,
                    ...doc.data()
                }
            }
            );
            resolve(jobsList)
        }
        catch (error) {
            reject(error)
            console.log(error);
        }
    })
}

