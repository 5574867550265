import Navbar from "../../components/navbar/Navbar"
import Sidebar from "../../components/sidebar/Sidebar"
import Widget from "../../components/widget/Widget"
import Featured from "../../components/featured/Featured"
import Chart from "../../components/chart/Chart"
import Table from "../../components/table/Table"
import "./home.scss"
import useAuth from "../../hooks/useAuth"
import { useNavigate } from "react-router-dom"
import { useContext, useEffect } from "react"
import { userStateContext } from "../../context/userStateContext"

const Home = () => {
  const { user } = useContext(userStateContext);
  const navigate = useNavigate();

  useEffect(() => {
    console.log('render',user);
    if (null == user || false == user.loggedIn) {
      navigate('/login')
    }
  }, [user]);


  return (
    <div className="home">
      <Sidebar />
      <div className="homeContainer">
        <Navbar />
        <div className="widgets">
          <Widget type="user" />
          <Widget type="reservation" />
        </div>
        {/* <div className="charts">
          <Featured />
          <Chart title="Last 6 Months (Revenue)" aspect={2 / 1} />
        </div> */}
        <div className="listContainer">
          <div className="listTitle">Clients</div>
          <Table />
        </div>
      </div>
    </div>
  )
}
export default Home
