
export function toLocaleDateString(timestamp) {
    let d = new Date(timestamp)
    return d.toLocaleDateString();
} 


export function timeAgo(timestamp) {
    const now = new Date();
    const secondsAgo = Math.floor((now - timestamp) / 1000);

    if (secondsAgo < 60) {
        return 'il y a quelques instants';
    } else if (secondsAgo < 3600) {
        // const minutesAgo = Math.floor(secondsAgo / 60);
        return 'il y a quelques minutes';
    } else if (secondsAgo < 86400) {
        // const hoursAgo = Math.floor(secondsAgo / 3600);
        // return hoursAgo + 
        return 'il y a quelques heures';
    } else if (secondsAgo < 86400 * 30) {
        const daysAgo = Math.floor(secondsAgo / 86400);
        // return daysAgo 
        return `il y a ${daysAgo} jour`;
    }else if(secondsAgo > 86400 * 30){
        const monthsAgo = Math.floor(secondsAgo / (86400 * 30));
        return `il y a ${monthsAgo} mois`;
    } else {
        return toLocaleDateString(timestamp)
    }
}

