import { useState, useEffect } from 'react'
import { DataGrid } from '@mui/x-data-grid'
import { MessageColumns} from '../../datatablesource'
import { Link } from 'react-router-dom'
import VisibilityIcon from '@mui/icons-material/Visibility'
// Hooks
import { useMessages } from '../../hooks/useMessages'
import { useMessagesCount } from '../../hooks/useMessagesCount'
import './datatable.scss'

const InboxDataTable = () => {
  const elementsPerPage = 10

  const [options, setOptions] = useState({
    page: 0,
    lastVisible: null,
    elementsPerPage: elementsPerPage
  })

  const [messages, loading, error] = useMessages(options);
  const [loadingRowsCount, errorRowsCount, rowsCount] = useMessagesCount()
  const [cursors, setCursors] = useState({})

  useEffect(() => {
    console.log(messages);
    setCursors(prev => {
      return {
        ...prev,
        [options.page]: {
          lastVisible: messages[messages.length - 1]
        }
      }
    })
  }, [messages])


  const handleOnPageChange = page => {

    setOptions(prev => {
      if (page == 0) {
        return {
          ...prev,
          page: 0,
          lastVisible: null
        }
      } else {

        if (page < prev.page) {
          return {
            ...prev,
            page,
            lastVisible: cursors[page - 1]?.lastVisible
          }
        }

        return {
          ...prev,
          page,
          lastVisible: messages[messages.length - 1]
        }
      }
    })
  }

  const actionColumn = [
    {
      field: 'action',
      headerName: 'Actions',
      width: 400,
      renderCell: params => {
        return (
          <div className='cellAction'>
            <Link
              className='view-reservation'
              to={`/inbox/${params.row.id}`}
            >
              <VisibilityIcon />
            </Link>
          </div>
        )
      }
    }
  ]

  return (
    <div className='datatable'>
      <div className='datatableTitle'>
        Inbox / Messages
      </div>

      <DataGrid
        loading={loading}
        rows={messages}
        columns={MessageColumns.concat(actionColumn)}
        pagination
        pageSize={elementsPerPage}
        rowCount={rowsCount}
        paginationMode='server'
        onPageChange={handleOnPageChange}
        className='datagrid'
      />
    </div>
  )
}

export default InboxDataTable
