import { useState, useEffect } from 'react'
import { DataGrid } from '@mui/x-data-grid'
import { ReservationColumns } from '../../datatablesource'
import { Link } from 'react-router-dom'
import VisibilityIcon from '@mui/icons-material/Visibility'
import DeleteIcon from '@mui/icons-material/Delete';
// Hooks
import { useReservation } from '../../hooks/useReservation'
import { useReservationCount } from '../../hooks/useReservationCount'
import { deleteReservationById } from '../../service/reservation'
import './datatable.scss'

const ReservationDataTable = () => {
  const elementsPerPage = 10

  const [options, setOptions] = useState({
    page: 0,
    lastVisible: null,
    elementsPerPage: elementsPerPage
  })

  const [reservations, loading, error, reload] = useReservation(options)
  const [loadingRowsCount, errorRowsCount, rowsCount] = useReservationCount()
  const [cursors, setCursors] = useState({})

  useEffect(() => {
    setCursors(prev => {
      return {
        ...prev,
        [options.page]: {
          lastVisible: reservations[reservations.length - 1]
        }
      }
    })
  }, [reservations])

  // debug show values of cursors
  useEffect(() => {
    console.log('cursors', cursors)
  }, [cursors])

  const handleDeleteReservation = async reservationId => {
    deleteReservationById(reservationId)
    reload()
  }

  const handleOnPageChange = page => {
    setOptions(prev => {
      if (page == 0) {
        return {
          ...prev,
          page: 0,
          lastVisible: null
        }
      } else {
        if (page < prev.page) {
          return {
            ...prev,
            page,
            lastVisible: cursors[page - 1]?.lastVisible
          }
        }

        return {
          ...prev,
          page,
          lastVisible: reservations[reservations.length - 1]
        }
      }
    })
  }

  const actionColumn = [
    {
      field: 'action',
      headerName: 'Actions',
      width: 400,
      renderCell: params => {
        return (
          <div className='cellAction'>
            <Link
              className='view-reservation'
              to={`/reservation/${params.row.id}`}
            >
              <VisibilityIcon />
            </Link>
            <button
            className="deleteButton"
              onClick={() => {
                handleDeleteReservation(params.row.id)
              }}
            >
              <DeleteIcon />
            </button>
          </div>
        )
      }
    }
  ]

  return (
    <div className='datatable'>
      <div className='datatableTitle'>
        Liste de Reservations
        <Link to='/reservation/new' className='link'>
          Nouvelle Réservation
        </Link>
      </div>

      <DataGrid
        loading={loading}
        rows={reservations}
        columns={ReservationColumns.concat(actionColumn)}
        pagination
        pageSize={elementsPerPage}
        rowCount={rowsCount}
        paginationMode='server'
        onPageChange={handleOnPageChange}
        className='datagrid'
      />
    </div>
  )
}

export default ReservationDataTable
