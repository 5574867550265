import './Add.scss'
import Sidebar from '../../components/sidebar/Sidebar'
import Navbar from '../../components/navbar/Navbar'
import { Alarm, DriveFolderUploadOutlined } from '@mui/icons-material'
import { useContext, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { userStateContext } from '../../context/userStateContext'
import { getServiceCatogories } from '../../service/category'
import {
  addService,
  addServiceWithMultipleSessions,
  getServicesWithSingleSession,
  deleteServiceById,
  getSubscriptionServices,
  deleteSubscriptionById
} from '../../service/Service'

import ListSubheader from '@mui/material/ListSubheader'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Collapse from '@mui/material/Collapse'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import StarBorder from '@mui/icons-material/StarBorder'
import DeleteIcon from '@mui/icons-material/Delete'
import CircularProgress from '@mui/material/CircularProgress'

import PendingActionsIcon from '@mui/icons-material/PendingActions'
import Divider from '@mui/material/Divider'

const CategoryItem = ({
  category,
  onCategorieClicked,
  handleDeleteService
}) => {
  return (
    <>
      <ListItemButton
        onClick={() => {
          onCategorieClicked(category)
        }}
      >
        <ListItemIcon>
          <PendingActionsIcon />
        </ListItemIcon>
        <ListItemText
          primary={category['name']}
          secondary={`Services  - ${category['services'].length}`}
        />
        {category['open'] ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={category['open']} timeout='auto' unmountOnExit>
        <List component='div' disablePadding>
          {category.services.map(service => {
            return (
              <ListItemButton
                key={service.id}
                sx={{ pl: 4 }}
                style={{ position: 'relative' }}
              >
                <ListItemIcon>
                  <StarBorder />
                </ListItemIcon>
                <ListItemText
                  primary={`${service.serviceName}   .   ${service.servicePrice}/MAD    .   ${service.pointPerSerice}/Points+`}
                />
                <button
                  onClick={() => handleDeleteService(service, category.id)}
                  style={{
                    position: 'absolute',
                    width: '50px',
                    height: '50px',
                    right: '50px',
                    background: 'transparent',
                    color: 'black'
                  }}
                >
                  <DeleteIcon />
                </button>
              </ListItemButton>
            )
          })}
          {!category.services.length && (
            <div style={{ paddingLeft: '70px' }}>
              <h5>Aucun service associé avec cette catégorie</h5>
            </div>
          )}
        </List>
      </Collapse>
    </>
  )
}

const SubscriptionItem = ({
  category,
  onSubscriptionCategoryClicked,
  handleDeleteSubscription
}) => {
  console.log(category)
  return (
    <>
      <ListItemButton
        onClick={() => {
          onSubscriptionCategoryClicked(category)
        }}
      >
        <ListItemIcon>
          <PendingActionsIcon />
        </ListItemIcon>
        <ListItemText
          primary={category['name']}
          secondary={`Services  - ${category['subscriptions'].length}`}
        />
        {category['open'] ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={category['open']} timeout='auto' unmountOnExit>
        <List component='div' disablePadding>
          {category['subscriptions'].map(service => {
            return (
              <ListItemButton
                key={service.id}
                sx={{ pl: 4 }}
                style={{ position: 'relative' }}
              >
                <ListItemIcon>
                  <StarBorder />
                </ListItemIcon>
                <ListItemText
                  primary={`${service.serviceName}   . Session : ${service.sessionsCount}   ${service.servicePrice}/MAD    .   ${service.pointPerSerice}/Points+`}
                />
                <button
                  onClick={() => handleDeleteSubscription(service, category.id)}
                  style={{
                    position: 'absolute',
                    width: '50px',
                    height: '50px',
                    right: '50px',
                    background: 'transparent',
                    color: 'black'
                  }}
                >
                  <DeleteIcon />
                </button>
              </ListItemButton>
            )
          })}
          {!category.subscriptions.length && (
            <div style={{ paddingLeft: '70px' }}>
              <h5>Aucune Abonnement associé avec cette catégorie</h5>
            </div>
          )}
        </List>
      </Collapse>
    </>
  )
}

const ListServices = () => {
  const [loading, setLoading] = useState(true)
  const [categories, setCategories] = useState([])
  const [categoriesWithManySessions, setcategoriesWithManySessions] = useState([])

  const navigate = useNavigate()

  /**
   * Get services categories
   * and services and
   * Associate every category of a bunch of services
   */
  useEffect(() => {
    async function init () {
      const data = await getServiceCatogories()
      const servicesDocs = await getServicesWithSingleSession()
      const servicesWithMultiplesSessionsDocs = await getSubscriptionServices()

      // fetch all services and associate them with the Categories
      const services_collapse = data.map(category => {
        let srs = []
        let categoryId = category.id

        return {
          ...category,
          services: [
            ...servicesDocs.filter(
              service => service.selectedCategoryId == categoryId
            )
          ],
          open: false
        }
      })

      // fetch all subscriptions and associate them with the Categories
      const subscription_collapse = data.map(category => {
        let srs = []
        let categoryId = category.id

        return {
          ...category,
          subscriptions: [
            ...servicesWithMultiplesSessionsDocs.filter(
              subscription => subscription.selectedCategoryId == categoryId
            )
          ],
          open: false
        }
      })

      setcategoriesWithManySessions(subscription_collapse)
      setCategories(services_collapse)
      setLoading(false)
    }
    init()
  }, [])

  const handleCategoryClicked = clickedCategory => {
    // Flip  open bit
    const { id } = clickedCategory
    const newCategories = categories.map(old_categ => {
      if (old_categ['id'] == id) {
        return {
          ...old_categ,
          open: !old_categ['open']
        }
      } else {
        return old_categ
      }
    })
    setCategories(newCategories)
  }

  const handleSubscriptionCategoryClicked = clickedCategory => {
    // Flip  open bit
    const { id } = clickedCategory
    const newCategories = categoriesWithManySessions.map(old_categ => {
      if (old_categ['id'] == id) {
        return {
          ...old_categ,
          open: !old_categ['open']
        }
      } else {
        return old_categ
      }
    })
    setcategoriesWithManySessions(newCategories)
  }

  const handleDeleteService = async (service, categoryId) => {
    deleteServiceById(service.id)
    const newCategories = categories.map(oldCat => {
      if (oldCat.id == categoryId) {
        let serviceIdToDelete = service.id
        let oldServices = oldCat.services
        let indexToDelete = false

        oldCat.services.forEach((service, index) => {
          if (service.id == serviceIdToDelete) {
            indexToDelete = index
          }
        })

        if (indexToDelete !== false) {
          oldCat.services.splice(indexToDelete, 1)
        }
        return oldCat
      } else {
        return oldCat
      }
    })

    setCategories(newCategories)
  }

  const handleDeleteSubscription = async (service, categoryId) => {
    deleteSubscriptionById(service.id)

    const newCategories = categoriesWithManySessions.map(oldCat => {
      if (oldCat.id == categoryId) {
        let serviceIdToDelete = service.id
        let oldServices = oldCat.subscriptions
        let indexToDelete = false

        oldCat.subscriptions.forEach((service, index) => {
          if (service.id == serviceIdToDelete) {
            indexToDelete = index
          }
        })

        if (indexToDelete !== false) {
          oldCat.subscriptions.splice(indexToDelete, 1)
        }
        return oldCat
      } else {
        return oldCat
      }
    })

    setcategoriesWithManySessions(newCategories)
  }

  return (
    <div className='new'>
      <Sidebar />

      <div className='newContainer'>
        <Navbar />
        <div className='content'>
          {loading && (
            <div
              style={{
                display: 'flex',
                minHeight: '200px',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <CircularProgress />
            </div>
          )}
          {!loading && (
            <List
              sx={{
                width: '100%',
                maxWidth: '100%',
                bgcolor: 'background.paper'
              }}
              component='nav'
              aria-labelledby='nested-list-subheader'
              subheader={
                <ListSubheader component='div' id='nested-list-subheader'>
                  Préstation avec une séance
                </ListSubheader>
              }
            >
              {categories.map((ctr, index) => (
                <CategoryItem
                  category={ctr}
                  key={ctr['id']}
                  onCategorieClicked={handleCategoryClicked}
                  handleDeleteService={handleDeleteService}
                />
              ))}
            </List>
          )}
          <Divider />

          {!loading && <List
            sx={{
              width: '100%',
              maxWidth: '100%',
              bgcolor: 'background.paper'
            }}
            component='nav'
            aria-labelledby='nested-list-subheader'
            subheader={
              <ListSubheader component='div' id='nested-list-subheader'>
                Les abonnements / nombreuses sessions
              </ListSubheader>
            }
          >
            {categoriesWithManySessions.map((ctr, index) => (
              <SubscriptionItem
                category={ctr}
                key={ctr['id']}
                onSubscriptionCategoryClicked={
                  handleSubscriptionCategoryClicked
                }
                handleDeleteSubscription={handleDeleteSubscription}
              />
            ))}
          </List>}
        </div>
      </div>
    </div>
  )
}

export default ListServices
