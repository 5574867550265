import {
  collection,
  addDoc,
  getDocs,
  query,
  where,
  doc,
  orderBy,
  setDoc,
  increment,
  getDoc,
  deleteDoc,
  startAfter,
  limit
} from 'firebase/firestore/lite'

import { DB } from '../App'

export async function getReservations (options) {
  let defaults = { elementsPerPage: 10, order: { created_at: 'desc' } }

  let opts = {
    ...defaults,
    ...options
  }

  const [[key], [val]] = [Object.keys(opts.order), Object.values(opts.order)]
  const { elementsPerPage, lastVisible } = opts
  const collectionRef = collection(DB, `/Reservation`)

  console.log(key, val)
  let q

  if (lastVisible === null) {
    q = query(collectionRef, orderBy(key, val), limit(elementsPerPage))
  } else {
    const { id } = lastVisible
    let startNextAfterDoc = await getDoc(doc(collectionRef, id))
    q = query(
      collectionRef,
      orderBy(key, val),
      startAfter(startNextAfterDoc),
      limit(elementsPerPage)
    )
  }

  try {
    const clientsSnapshot = await getDocs(q)
    return clientsSnapshot.docs
  } catch (error) {
    console.log(error)
    return Promise.reject(error)
    return error
  }
}

export async function getReservationsCount () {
  const collectionRef = collection(DB, `/Reservation`)
  try {
    const clientsSnapshot = await getDocs(collectionRef)
    return clientsSnapshot.size
  } catch (error) {
    return Promise.reject(error)
  }
}

export async function addNewReservation (doc) {
  console.log('addNewReservation called')
  const collectionRef = collection(DB, `/Reservation`)
  return addDoc(collectionRef, doc)
}

export async function getNewReservationsCount () {
  console.log('calling getNewReservationsCount')
  const collecitonRef = collection(DB, `/Reservation`)
  const q = query(collecitonRef, where('status', '==', 'NEW'))
  try {
    const clientsSnapshot = await getDocs(q)
    console.log('getNewReservationsCount finished', clientsSnapshot.size)
    return clientsSnapshot.size
  } catch (error) {
    return Promise.reject(error)
  }
}

export async function getReservationById (reservaionId) {
  // check if there is no message throw an error
  if (!reservaionId)
    return Promise.reject(
      `get reservation by id expects id ${typeof reservaionId} passed`
    )
  const collectionRef = collection(DB, `/Reservation`)
  const reservationDocRef = doc(collectionRef, reservaionId)
  const reservation = getDoc(reservationDocRef)
  return reservation
}

export async function updateReservationStatus (reservationId, newStatus) {
  if (!reservationId) {
    return Promise.reject('expecting a reservation id')
  }

  const reservationDocRef = doc(DB, 'Reservation', reservationId)
  return await setDoc(
    reservationDocRef,
    {
      status: newStatus
    },
    { merge: true }
  )
}



export async function deleteReservationById(reservaionId){
  if(!reservaionId)
  throw new Error('expected a reservation id');
  const collectionRef = collection(DB, `/Reservation`)
  const reservationDocRef = doc(collectionRef, reservaionId);
  const deletedDoc = await deleteDoc(reservationDocRef);
}