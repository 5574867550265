import './datatable.scss'
import { useState } from 'react'
import { useEffect } from 'react'
import { DataGrid } from '@mui/x-data-grid'

import { TransactionsColumns } from '../../datatablesource'
import { GetPurchasesForUser } from '../../service/user'
import { getPurchasesCountByClientId } from './../../service/Purchase'

export default function ClientTransactions ({ userId }) {
  const PAGE_SIZE = 10
  const [page, setPage] = useState(0)
  const [lastVisible, setLastVisible] = useState(null)
  const [rowsCount, setRowsCount] = useState(0)
  const [ClientTransactions, setClientTransactions] = useState([])
  const [loading, setLoading] = useState(false)
  const [cursors, setCursors] = useState({})

  // when the component mounts, we fetch the number of rows

  useEffect(() => {
    const getRowsCount = async () => {
      const transactionsCount = await getPurchasesCountByClientId(userId)
      setRowsCount(transactionsCount)
      console.log(
        `the transactions count for user with id ${userId} is `,
        transactionsCount
      )
    }
    getRowsCount()
  }, [])

  // when the page changes, we fetch the next page

  useEffect(() => {
    setLoading(true)
    // getTransaction()
    async function getTransaction () {
      const transactions = await GetPurchasesForUser({ userId, lastVisible })
      setClientTransactions(transactions)
      setCursors(prev => {
        return {
          ...prev,
          [page]: {
            lastVisible: transactions[transactions.length - 1],
            firstVisible: transactions[0]
          }
        }
      })
      console.log(
        `the transactions for user with id ${userId} are `,
        transactions
      )
      setLoading(false)
    }
    getTransaction()
  }, [userId, page])

  const handleOnPageChange = page => {
    if (page == 0) {
      setLastVisible(null)
      setPage(page)
      return
    } else {
      setLastVisible(cursors[page - 1].lastVisible)
      setPage(page)
    }
  }

  return (
    <div className='datatable'>
      <DataGrid
        loading={loading}
        rows={ClientTransactions}
        columns={TransactionsColumns}
        pagination
        pageSize={PAGE_SIZE}
        rowCount={rowsCount}
        paginationMode='server'
        onPageChange={handleOnPageChange}
        className='datagrid'
      />
    </div>
  )
}
