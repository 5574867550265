import './sidebar.scss'
import DashboardIcon from '@mui/icons-material/Dashboard'
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined'
import StoreIcon from '@mui/icons-material/Store'
import CreditCardIcon from '@mui/icons-material/CreditCard'
import LocalShippingIcon from '@mui/icons-material/LocalShipping'
import AnalyticsIcon from '@mui/icons-material/Analytics'
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined'
import SettingsSystemDaydreamOutlinedIcon from '@mui/icons-material/SettingsSystemDaydreamOutlined'
import PsychologyOutlinedIcon from '@mui/icons-material/PsychologyOutlined'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined'
import InputOutlinedIcon from '@mui/icons-material/InputOutlined'
import { ProductionQuantityLimitsOutlined } from '@mui/icons-material'
import MailIcon from '@mui/icons-material/Mail'
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';

import { Link } from 'react-router-dom'
import { useContext } from 'react'
import { DarkModeContext } from '../../context/darkModeContext'
import { CalendarMonth } from '@mui/icons-material'
import { userStateContext } from '../../context/userStateContext'
import {
  getAuth,
  signOut,
  signInWithEmailAndPassword,
  setPersistence,
  browserSessionPersistence
} from 'firebase/auth'

const Sidebar = () => {
  const auth = getAuth()
  const { dispatch } = useContext(DarkModeContext)
  const userAction = useContext(userStateContext)

  const handleLogout = async () => {
    signOut(auth)
    //userAction.dispatch({ type: 'LOGOUT' })
  }

  return (
    <div className='sidebar'>
      <div className='top'>
        <Link to='/' style={{ textDecoration: 'none' }}>
          <span className='logo branding'>Viola</span>
        </Link>
      </div>
      <hr />
      <div className='center'>
        <ul>
          <p className='title'>MAIN</p>
          <Link to='/' style={{ textDecoration: 'none' }}>
            <li>
              <DashboardIcon className='icon' />
              <span>Tableau de bord</span>
            </li>
          </Link>

          <Link to='/purchase/new' style={{ textDecoration: 'none' }}>
            <li>
              <ProductionQuantityLimitsOutlined className='icon' />
              <span>Nouveau Achat</span>
            </li>
          </Link>

          <p className='title'>Clients</p>
          <Link to='/users' style={{ textDecoration: 'none' }}>
            <li>
              <PersonOutlineOutlinedIcon className='icon' />
              <span>Clients</span>
            </li>
          </Link>
          <Link to='/reservation' style={{ textDecoration: 'none' }}>
            <li>
              <CalendarMonth className='icon' />
              <span>Rendez-Vous</span>
            </li>
          </Link>
          
          <p className='title'>Catégories</p>

          <Link to='/category/' style={{ textDecoration: 'none' }}>
            <li>
              <StoreIcon className='icon' />
              <span>Catégories</span>
            </li>
          </Link>

          <Link to='/category/add' style={{ textDecoration: 'none' }}>
            <li>
              <StoreIcon className='icon' />
              <span>Ajouter Catégorie</span>
            </li>
          </Link>
          
          <p className='title'>Services</p>

          <Link to='/service/add' style={{ textDecoration: 'none' }}>
            <li>
              <StoreIcon className='icon' />
              <span>Ajouter Service</span>
            </li>
          </Link>

          <Link to='/service/' style={{ textDecoration: 'none' }}>
            <li>
              <StoreIcon className='icon' />
              <span>Les Services</span>
            </li>
          </Link>
          
          <p className='title'>Inbox</p>

          <Link to='/inbox' style={{ textDecoration: 'none' }}>
            <li>
              <MailIcon className='icon' />
              <span>Les Messages</span>
            </li>
          </Link>

          <p className='title'>Cadeau</p>
          
          <Link to='/gift/' style={{ textDecoration: 'none' }}>
            <li>
              <CardGiftcardIcon className='icon' />
              <span>Les cadeaux</span>
            </li>
          </Link>

          <Link to='/gift/add' style={{ textDecoration: 'none' }}>
            <li>
              <CardGiftcardIcon className='icon' />
              <span>Nouveau cadeau</span>
            </li>
          </Link>


          <p className='title'>MOBILE</p>

          <Link to='#' style={{ textDecoration: 'none' }}>
            <li>
              <NotificationsNoneOutlinedIcon className='icon' />
              <span>Nouvelle Notifications</span>
            </li>
          </Link>



          <p className='title'>Utilisateur</p>
          <li>
            <a onClick={() => handleLogout()}>
              <InputOutlinedIcon className='icon' />
              <span>Logout</span>
            </a>
          </li>
        </ul>
      </div>
      <div className='bottom'>
        <div
          className='colorOption'
          onClick={() => dispatch({ type: 'LIGHT' })}
        ></div>

        <div
          className='colorOption'
          onClick={() => dispatch({ type: 'DARK' })}
        ></div>
      </div>
    </div>
  )
}

export default Sidebar
