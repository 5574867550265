import { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import "./style.scss"

// Components

import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { getServiceCatogories } from "../../service/category";
import { getServicesByCategoryId, getServicesWithMultipleSessions } from "../../service/Service"
import { LookupClientByPhone } from "../../service/user";
import { recordPurchase } from "../../service/Purchase";
import { RecordPurchaseForUser, IncrementClientScore } from "../../service/user";
import { getSubscriptionsByClientId, AttachSubscriptionToUserWithId } from "../../service/Subscription";

// ICONS 

import { Search } from "@mui/icons-material";
import { AccountCircleRounded } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";


/**
 * @TODO Move this to the components folder
 */
// inline components 

const UserCard = ({ details, OnToggleClientSelect }) => {
    const { id, fullName, phone, selected } = details;
    return (
        <div className={`userCard ${selected ? 'selected' : ''}`} onClick={() => OnToggleClientSelect(id)}>
            <div className="rounderProfile">
                <AccountCircleRounded color={'primary'} />
            </div>
            <span>{fullName}</span>
            <span>{phone}</span>
        </div>
    )
}

const ClientsList = ({ clients, OnToggleClientSelect }) => {
    return (
        <div className="clientsList ">
            {clients && clients.map((client) => <UserCard key={client.id} details={client} OnToggleClientSelect={OnToggleClientSelect} />)}
        </div>
    )
}


const RocordedServicesBottomLine = ({ recordedServicesList }) => {

    const calculatedTotale = recordedServicesList.reduce((accum, service) => { return accum + parseInt(service.price) }, 0);

    return (
        <div className="rocordedServicesBottomLine">
            <div className="divider" />
            <header>
                <h3>Totales de Service Acheté</h3>
                <h3>{recordedServicesList.length}</h3>
            </header>
            <main>
                <ul>
                    {recordedServicesList.map(({ bought, serviceCategory, price }, i) =>
                    (
                        <li key={i}>
                            <span className="service">{bought}</span>
                            <span>{serviceCategory}</span>
                            <span>{price}</span>
                        </li>
                    )
                    )}
                </ul>
            </main>
            <div className="totaleContainer">
                <h1>Total : </h1>
                <h1>{calculatedTotale}</h1>
            </div>
        </div>
    )
}



const MultipleServiceSessionsDetails = ({ service }) => {
    if (typeof service == "boolean" && service === false) return;
    const { pointPerSerice, serviceName, servicePrice, sessionsCount } = service;
    return (
        <div className="multipleServiceSessionDisplaycontainer">
            <h2> <span className="servicename">{serviceName}</span> dispose de {sessionsCount} séances et coûte <span className="price">{servicePrice}</span> MAD </h2>
            <h3>{pointPerSerice} points seront ajoutés à l'utilisateur une fois le service payé intégralement</h3>
        </div>
    )
}


const SessionsRenderer = ({ count, decrementSessionsWithOne, toggleIncludeFirstSession }) => {
    return (
        <div className="sessions-container">
            <h4>Sessions</h4>
            <div className="sessions-renderer">
                {
                    Array.from({ length: count }).map((session, index) => {
                        if (index == 0) {
                            return (
                                <div key={index} className={`session item ${decrementSessionsWithOne === 1 ? 'selected' : ''}`} onClick={() => toggleIncludeFirstSession()} />
                            )
                        }
                        return (
                            <div key={index} className="session item" />
                        )
                    })
                }
            </div>

        </div>
    )
}


const NewPurchase = () => {

    const navigate = useNavigate();

    const NEW_PURCHASE_MODE = {
        SIMPLE: "SIMPLE",
        SUBSCRIPTION: "SUBSCRIPTION"
    };

    const [selectedCategoryId, setSelectedCatogoryId] = useState(null)
    const [fetchingServices, setFetchingServices] = useState(false);
    const [selectedServiceId, setSelectedServiceId] = useState(null)
    const [errorMessage, setErrorMessage] = useState(null);
    const [services, setServices] = useState([]);
    const [categories, setCategories] = useState([]);
    const [phoneSearch, setPhoneSearch] = useState("06")
    const [phoneSearchResult, setPhoneSearchResult] = useState([]);
    const [recordingPurchaseInProgress, setRecordingPurchaseInProgress] = useState(false);
    const [recordedServicesList, setRocordedServicesList] = useState([]);

    // add a buyer
    const [buyerId, setBuyerId] = useState("-1");
    const [servicesWithMuliSessions, setServicesWithMultipleSessions] = useState([]);
    const [subscriptions, setSubscriptions] = useState([]);
    const [purchaseMode, setPurchaseMode] = useState(NEW_PURCHASE_MODE['SIMPLE'])
    const [selectedSubscriptionService, setSelectedSubscriptionService] = useState("-1");
    const [decrementSessionsWithOne, setDecrementSessionsWithOne] = useState(1);



    useEffect(() => {
        (async function init() {
            // Get Categories ,
            const serviceCategories = await getServiceCatogories();
            setCategories(serviceCategories)
        })();
    }, [])


    useEffect(() => {
        (async function init() {
            if (null !== selectedCategoryId) {
                const servicesDocs = await getServicesByCategoryId(selectedCategoryId);
                let data = servicesDocs.map((doc) => {
                    return {
                        id: doc.id,
                        ...doc.data()
                    }
                })
                setFetchingServices(false)
                setServices(data);
            }
        })()
        // Get a services by the selected Category
    }, [selectedCategoryId]);

    /**
     * When the list of Clients change find subscriptions associated with the current user
     */
    useEffect(() => {
        let selectedBuyerList = phoneSearchResult.filter((customer) => customer.selected == true);
        if (selectedBuyerList.length) {
            setBuyerId(selectedBuyerList[0]['id']);
            (async (userId) => {
                const clientSubsciptions = await getSubscriptionsByClientId(userId);
                setSubscriptions(clientSubsciptions.docs);
            })(selectedBuyerList[0]["id"])
        } else {
            setBuyerId('-1')
        }
    }, [phoneSearchResult])


    const handleSelectCategoryId = (e) => {
        const categoryId = e.target.value;
        setFetchingServices(true);
        setSelectedCatogoryId(categoryId);
    }

    const handleSetSelectedServiceId = (e) => {
        const serviceId = e.target.value;
        setSelectedServiceId(serviceId);
    }


    const handleNewPurchase = async () => {


        let buyer = buyerId;
        // check if a user is selected
        setRecordingPurchaseInProgress(true)
        // const [buyer] = phoneSearchResult.filter((customer) => customer.selected == true)

        if (!buyer || buyer === undefined || buyer == "-1") {
            setErrorMessage("aucun client n'est sélectionné, Veuillez sélectionner un client");
            setRecordingPurchaseInProgress(false);
        }
        
        const buyerData = phoneSearchResult.filter((b) => b['id'] == buyer)[0]

        if (purchaseMode === "SUBSCRIPTION") {

            const selectedServices = servicesWithMuliSessions.filter((service) => service.id == selectedSubscriptionService);

            if (!selectedServices.length) {
                setErrorMessage("Aucun service n'est sélectionné, Veuillez sélectionner un service")
                setRecordingPurchaseInProgress(false);
                return;
            }

            let subscDoc = {
                ...selectedServices[0],
                sessionsCount: parseInt(selectedServices[0]["sessionsCount"]),
                sessionPerformed: parseInt(decrementSessionsWithOne),
                lastSessionOn: +new Date(),
            }
            try {
                await AttachSubscriptionToUserWithId(buyer, subscDoc);
                // redirect the user page with id;
                navigate(`/users/${buyer}`);
            } catch (error) {
                setErrorMessage("Erreur Échec de l'ajout de l'abonnement");
                setRecordingPurchaseInProgress(false);
            }

            return;
        }


        // Get choosen Service meta data
        const [choosenService] = selectedServiceId && services.filter((service) => service.id == selectedServiceId);
        if (choosenService === undefined) {
            setRecordingPurchaseInProgress(false);
            setErrorMessage("aucun service n'est sélectionné, Veuillez sélectionner un service");
        }
        // Get category of the service
        const [choosenCategory] = selectedCategoryId && categories.filter((category) => category.id == selectedCategoryId);

        if (choosenCategory === undefined) {
            setErrorMessage("aucune Categorie du  n'est sélectionné, Veuillez sélectionner une categorie")
            setRecordingPurchaseInProgress(false);
        }

        /**
         * DISPLAY Spinner loading   
         */

        const incrementCustomerScore = choosenService.pointPerSerice;


        const purchaseData = {
            buyerId: buyer,
            serviceCategory: choosenCategory.name,
            buyerFullName: buyerData.fullName,
            buyerPhone: buyerData.phone,
            bought: choosenService.serviceName,
            price: choosenService.servicePrice,
            transactionTime: +new Date(),
        }

        // Add purchase Data to /Purchase Collection
        const purchaseRecorded = await recordPurchase(purchaseData);
        const recordedSaleId = purchaseRecorded.id;

        const dataSalePerUser = {
            ...purchaseData,
            purchaseCollectionId: recordedSaleId,
        };

        delete dataSalePerUser.buyerId;
        delete dataSalePerUser.buyerFullName;
        delete dataSalePerUser.buyerPhone;

        /**
         * @TODO execute a cloud function to update the /User/Purchase collection
         * will improve security and performance 
         * Add purchase Data to sub collection in /User/Purchase to avoid Scanning the entire collection
         */

        await RecordPurchaseForUser(purchaseData.buyerId, dataSalePerUser);
        // Increment Customer Score 
        await IncrementClientScore(purchaseData.buyerId, incrementCustomerScore);
        // clear the category of service
        // clear the service 
        // display a message that the service has been added
        setRocordedServicesList((old) => {
            return [...old, dataSalePerUser]
        })

        console.log(recordedServicesList)
        clearPreviousSelection();
        setRecordingPurchaseInProgress(false);
    }


    const handlePhoneOnChange = async (e) => {
        setPhoneSearch(e.target.value)
        const clientsLookUp = await LookupClientByPhone(phoneSearch);
        let docs = clientsLookUp.docs.map((clientSnapshot) => ({
            id: clientSnapshot.id,
            selected: false,
            ...clientSnapshot.data(),
        }))

        setPhoneSearchResult(docs);
    }


    const OnToggleClientSelect = (id) => {
        let newPhoneSearchResult = phoneSearchResult.map((client) => {
            if (client.id === id) {
                return (
                    {
                        ...client,
                        selected: !client.selected
                    })
            }
            return {
                ...client,
                selected: false
            }
        })
        // check if no user is selected set buyerId to -1

        setPhoneSearchResult(newPhoneSearchResult);
    }


    const clearPreviousSelection = () => {
        setSelectedCatogoryId(null)
        setFetchingServices(false)
        setSelectedServiceId(null)
    }


    const handleNewSubscription = (e) => {

        setPurchaseMode(NEW_PURCHASE_MODE['SUBSCRIPTION']);
        (async () => {
            let servicesWithMuliSessionsDocs = await getServicesWithMultipleSessions();
            let servicesWithMuliSessionsData = servicesWithMuliSessionsDocs.docs.map((doc) => ({
                id: doc.id,
                ...doc.data()
            }));
            setServicesWithMultipleSessions(servicesWithMuliSessionsData);
        })()
        e.preventDefault();
    }

    const toggleServiceType = () => {
        if (purchaseMode == "SUBSCRIPTION") {
            setPurchaseMode(NEW_PURCHASE_MODE['SIMPLE']);
        } else {
            setPurchaseMode(NEW_PURCHASE_MODE['SUBSCRIPTION']);
        }
    }


    const handleChangeSelectedSubscriptionService = (e) => {
        const subscriptionsServiceId = e.target.value;
        setSelectedSubscriptionService(subscriptionsServiceId)
    }



    const selectedSubService = useMemo(() => {
        if (selectedSubscriptionService == "-1") return false;
        const list = servicesWithMuliSessions.filter((service) => service.id == selectedSubscriptionService);
        if (list.length > 0) {
            return list[0];
        }
        return false;
    }, [servicesWithMuliSessions, selectedSubscriptionService]);

    return (
        <div className="new">
            <Sidebar />

            <div className="newContainer">
                <Navbar />
                <div className="mt" />
                {/* <div className="top">
                    <h1> Ajouter un Nouveau Achat </h1>
                </div> */}
                {/*display a success message*/}
                <ClientsList clients={phoneSearchResult} OnToggleClientSelect={OnToggleClientSelect} />
                <div className="formContainer">
                    <form className="addNewCategory addNewService">

                        <div className="errorContainer">
                            <p>
                                {errorMessage}
                            </p>
                        </div>

                        <div className="actionButtons">
                            <button
                                type="button"
                                className="new_subsc"
                                disabled={buyerId === "-1" || purchaseMode == "SUBSCRIPTION"}
                                onClick={handleNewSubscription}>Nouvelle Abonnement</button>

                            <button
                                type="button"
                                className="new_subsc"
                                disabled={buyerId === "-1" || purchaseMode == "SIMPLE"}
                                onClick={toggleServiceType}>Nouveau Service Simple</button>


                        </div>

                        <div>
                            <input type="text" name="phone" value={phoneSearch} placeholder="Identifier Client par Numero de telephone" onChange={handlePhoneOnChange} autoComplete="off" />
                            <button className="rounded search btn transparent">
                                <Search color={"primary"} />
                            </button>
                        </div>

                        {purchaseMode && purchaseMode == "SUBSCRIPTION" && (
                            <div className="multiple purchase-mode-container">
                                <div>
                                    <label>Service</label>
                                    <select value={selectedSubscriptionService} onChange={handleChangeSelectedSubscriptionService}>

                                        <option value={"-1"} onChange={handleChangeSelectedSubscriptionService}>Service</option>
                                        {

                                            servicesWithMuliSessions.map(({ serviceName, id }) => {
                                                return (
                                                    <option key={id} value={id} onChange={handleChangeSelectedSubscriptionService}>{serviceName}</option>
                                                )
                                            })
                                        }
                                    </select>

                                </div>

                                <div>
                                    <MultipleServiceSessionsDetails service={selectedSubService} />
                                </div>
                                <div>
                                    <SessionsRenderer
                                        count={selectedSubService && selectedSubService.sessionsCount}
                                        decrementSessionsWithOne={decrementSessionsWithOne}
                                        toggleIncludeFirstSession={() => setDecrementSessionsWithOne((old) => { return old ? 0 : 1 })}
                                    />
                                </div>

                            </div>
                        )
                        }

                        {purchaseMode && purchaseMode == "SIMPLE" && (
                            <div className="simple purchase-mode-container">
                                <div>
                                    <label>Categorie Du service</label>
                                    <select value={selectedCategoryId} onChange={handleSelectCategoryId}>
                                        <option selected={selectedCategoryId == null} > Catégorie du service</option>
                                        {

                                            categories.map(({ name, id }) => {
                                                return (
                                                    <option key={id} value={id} onChange={(() => setSelectedCatogoryId(id))}> {name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                                <div>
                                    <label>Service</label>
                                    <select value={selectedServiceId} onChange={handleSetSelectedServiceId}>
                                        {fetchingServices && <option defaultValue>Chargement de services..</option>}
                                        {!fetchingServices && <option defaultValue>Selectionnez le Service Acheté</option>}
                                        {

                                            !fetchingServices && selectedCategoryId && services.map(({ serviceName, id }) => {
                                                return (
                                                    <option key={id} value={id} onChange={(() => selectedServiceId(id))}> {serviceName}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                                <div>
                                </div>
                            </div>
                        )}
                        <button type="button" onClick={handleNewPurchase} disabled={recordingPurchaseInProgress}>
                            Ajouter  Nouveau Achat
                            {recordingPurchaseInProgress && <CircularProgress size={30} />}
                        </button>
                    </form>
                    {
                        /**
                         * DISPLAY BUTTOM LINE FOR ALL Added Services 
                         */
                        purchaseMode && purchaseMode == "SIMPLE" && <RocordedServicesBottomLine recordedServicesList={recordedServicesList} />
                    }
                </div>
            </div >
        </div >
    )
}



export default NewPurchase