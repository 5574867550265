import './list.scss'
import Sidebar from '../../components/sidebar/Sidebar'
import Navbar from '../../components/navbar/Navbar'
import { Alarm, DriveFolderUploadOutlined } from '@mui/icons-material'
import { useContext, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { userStateContext } from '../../context/userStateContext'
import { getServiceCatogories } from '../../service/category'

import {
  addService,
  addServiceWithMultipleSessions,
  getServicesWithSingleSession,
  deleteServiceById,
  getSubscriptionServices,
  deleteSubscriptionById
} from '../../service/Service'

import { getGifts,deleteGiftById } from '../../service/Gift'

import ListSubheader from '@mui/material/ListSubheader'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Collapse from '@mui/material/Collapse'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import StarBorder from '@mui/icons-material/StarBorder'
import DeleteIcon from '@mui/icons-material/Delete'
import CircularProgress from '@mui/material/CircularProgress'

import PendingActionsIcon from '@mui/icons-material/PendingActions'
import Divider from '@mui/material/Divider'

const GiftItem = ({
  gift,
  onDeleteGift,
  index
}) => {
  return (
    <>
      <ListItemButton>
        <ListItemIcon>
          <PendingActionsIcon />
        </ListItemIcon>
        <div>
          <ListItemText
            primary={gift['name']}
            secondary={`Points - ${gift['points']}`}
            style={{ position: 'relative' }}
          />
          <button
            onClick={() => onDeleteGift(gift,index)}
            style={{
              zIndex:1000,
              top:"0px",
              position: 'absolute',
              width: '50px',
              height: '50px',
              right: '50px',
              background: 'transparent',
              color: 'black'
            }}
          >
            <DeleteIcon />
          </button>
        </div>
      </ListItemButton>
    </>
  )
}

const ListGifts = () => {
  const [loading, setLoading] = useState(true)
  const [categories, setCategories] = useState([])
  const [gifts, setGifts] = useState([])
  const [categoriesWithManySessions, setcategoriesWithManySessions] = useState([])

  const navigate = useNavigate()

  useEffect(() => {
    async function init () {
      const giftsDocs = await getGifts()
      const data = giftsDocs.docs.map(gift => {
        return {
          id: gift.id,
          ...gift.data()
        }
      })
      console.log(data)
      setGifts(data)
      setLoading(false)
    }

    init()
  }, [])
 



  const handleOndeleteGift = (gift,index) => {
    deleteGiftById(gift.id)
    const newGifts = [...gifts];
    newGifts.splice(index,1);
    setGifts(newGifts);
  }

  return (
    <div className='new'>
      <Sidebar />

      <div className='newContainer'>
        <Navbar />
        <div className='content'>
          {loading && (
            <div
              style={{
                display: 'flex',
                minHeight: '200px',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <CircularProgress />
            </div>
          )}
          {!loading && (
            <List
              sx={{
                width: '100%',
                maxWidth: '100%',
                bgcolor: 'background.paper'
              }}
              component='nav'
              aria-labelledby='nested-list-subheader'
              subheader={
                <ListSubheader component='div' id='nested-list-subheader'>
                  Le Cadeaux
                </ListSubheader>
              }
            >
              {gifts.map((gift, index) => (
                <GiftItem
                  gift={gift}
                  index={index}
                  key={gift['id']}
                  onDeleteGift={handleOndeleteGift}
                  //   onCategorieClicked={handleCategoryClicked}
                  //   handleDeleteService={handleDeleteService}
                />
              ))}
            </List>
          )}
          <Divider />
        </div>
      </div>
    </div>
  )
}

export default ListGifts
