import { useState, useEffect } from 'react'
import { getClients, getClientsCount } from '../service/user'

export const useClients = options => {
  const { limit, page, lastVisible } = options
  const [clients, setClients] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)

  const fetchData = async () => {
    setLoading(true)
    setClients([])
    try {
      let response = await getClients({ limitSize: limit, lastVisible })
      console.log(response)
      // get data
      let data = response.map(client => {
        return {
          id: client.id,
          ...client.data(),
          status: 'Active'
        }
      })
      setClients(data)
    } catch (error) {
      console.log(error)
      setError(error)
      setClients([])
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchData()
  }, [page])

  const reload = () => {
    fetchData()
  }

  return [clients, loading, error,reload]
}

export const useRowsCount = () => {
  const [rowsCount, setRowsCount] = useState(0)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      console.log('fetching rows count...')
      const rowsCnt = await getClientsCount()

      if (rowsCnt === -1) {
        setError("Erreur lor's de la recuperation du nombre de clients")
        setRowsCount(0)
        return
      }

      setRowsCount(rowsCnt)
      setLoading(false)
      setError(false)
    }
    fetchData()
  }, [])
  return [loading, error, rowsCount]
}
