import './single.scss'
import Sidebar from '../../components/sidebar/Sidebar'
import Navbar from '../../components/navbar/Navbar'
import Chart from '../../components/chart/Chart'
import List from '../../components/table/Table'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import {
  getClientDataById,
  GetPurchasesForUser,
  decrementScoreByPoints
} from '../../service/user'

import ClientTransactions from '../../components/datatable/ClientTransactions'
import Subscriptions from '../../components/subscription/Subscriptions'

import { getGifts } from '../../service/Gift'
import LinearProgress from '@mui/material/LinearProgress'
import CircularProgress from '@mui/material/CircularProgress'

const Xtimes = ({ x }) => {
  if (x == 0) return
  return (
    <>
      <span>X</span>
      <span
        style={{ fontWeight: 'bold', fontFamily: 'monospace' }}
      >{` ${x} `}</span>
    </>
  )
}

const GiftItem = ({ gift, userAccumulatedPoints, onRedeemGift }) => {
  const [updating, setUpdating] = useState(false)
  const gotEnoughtPoints = userAccumulatedPoints >= gift.points
  const MIN = 0
  const MAX = gift.points

  const normalise = value => ((value - MIN) * 100) / (MAX - MIN)

  const xValue =
    userAccumulatedPoints >= gift.points
      ? Math.trunc(userAccumulatedPoints / gift.points)
      : 0

  const handleRedeemGift = pointsToSubstractFromUser => {
    setUpdating(true)
    onRedeemGift(pointsToSubstractFromUser)
    setUpdating(false)
  }

  return (
    <div style={{ paddingTop: 10, paddingBottom: 10 }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between'
        }}
      >
        <h5>{gift.name}</h5>
        <h5>{gift.points}</h5>
      </div>

      <div style={{ minWidth: '980px', display: 'flex' }}>
        <div style={{ width: '800px' }}>
          <LinearProgress
            color='secondary'
            style={{ height: '20px' }}
            variant='determinate'
            value={gotEnoughtPoints ? 100 : normalise(userAccumulatedPoints)}
          />
        </div>

        <div style={{ paddingLeft: '10px' }}>
          <Xtimes x={xValue} />
        </div>

        {updating && gotEnoughtPoints && (
          <CircularProgress style={{ marginLeft: '15px', marginTop: '-7px' }} />
        )}

        {!updating && gotEnoughtPoints && (
          <button
            className='action-button'
            onClick={() => {
              handleRedeemGift(gift.points)
            }}
          >
            Échanger
          </button>
        )}
      </div>
    </div>
  )
}

const UserGifts = ({ userScore, userId, onScoreDecremented }) => {
  const [gifts, setGifts] = useState([])
  const [loadingGifts, setLoadingGifts] = useState(true)

  useEffect(() => {
    async function init () {
      const giftresponse = await getGifts()
      const giftResult = giftresponse.docs.map(gift => ({
        ...gift.data(),
        id: gift.id
      }))
      setGifts(giftResult)
      console.log('GETTING GIFTS')
      console.log(giftResult)
      setLoadingGifts(false)
    }
    init()
  }, [])

  const handleOnRedeemGift = pointsToSubstract => {
    onScoreDecremented(userId, pointsToSubstract)
  }

  return (
    <>
      {loadingGifts && <span>Chargement....</span>}
      {!loadingGifts &&
        gifts.map(gift => (
          <GiftItem
            key={gift.id}
            {...{ gift }}
            userAccumulatedPoints={userScore}
            onRedeemGift={handleOnRedeemGift}
          />
        ))}
    </>
  )
}

const Single = () => {
  const [loading, setLoading] = useState(true)
  const [clientData, setClientData] = useState({})
  const navigation = useNavigate()
  const { userId } = useParams()

  useEffect(() => {
    ;(async function getClient () {
      const userData = await getClientDataById(userId)
      setClientData(await userData.data())
      setLoading(false)
    })()
  }, [userId])

  const decrementClientScoreByPoints = async (userId, decrementBy) => {
    try {
      await decrementScoreByPoints(userId, decrementBy)

      setClientData(prev => {
        return {
          ...prev,
          score: prev.score - decrementBy
        }
      })
    } catch (error) {
      alert('ERROR')
      console.log(error)
    }
  }

  const handleOnSubscriptionFinished = updateScore => {
    setClientData(prev => {
      return {
        ...prev,
        score: prev.score + updateScore
      }
    })
  }

  return (
    <div className='single'>
      <Sidebar />
      <div className='singleContainer'>
        <Navbar />
        <div className='top'>
          <div className='left topItemContainer'>
            <div className='userinfos'>
              <h1 className='title'>Informations</h1>
              <div className='item'>
                <img
                  src='https://cdn.icon-icons.com/icons2/1736/PNG/512/4043251-avatar-female-girl-woman_113291.png'
                  alt=''
                  className='itemImg'
                />
                <div className='details'>
                  <h1 className='itemTitle'>{clientData.fullName}</h1>
                  <div className='detailItem'>
                    <span className='itemKey'>Phone :</span>
                    <span className='itemKey'>{clientData.phone}</span>
                  </div>
                  <div className='detailItem'>
                    <span className='itemKey'>Ville :</span>
                    <span className='itemKey'>Marraech</span>
                  </div>
                </div>
              </div>
            </div>
            <div className='scoreContainer'>
              <h3 className='title'>Points</h3>
              {loading && <h1 className='loading'>chargement...</h1>}
              {!loading && <h1 className='score'>{clientData.score}</h1>}
            </div>
          </div>
        </div>

        <div>
          <h1 className='title'>Abonnements / Pack</h1>
          <Subscriptions
            user={userId}
            onSubscriptionFinished={handleOnSubscriptionFinished}
          />
        </div>

        <div
          style={{
            maxWidth: '800px',
            minHeight: '200px',
            paddingLeft: '20px',
            paddingRight: '20px'
          }}
        >
          <h1 className='title' style={{ paddingLeft: '0px' }}>
            Cadeaux
          </h1>
          {!loading && (
            <UserGifts
              userScore={clientData.score}
              userId={userId}
              onScoreDecremented={decrementClientScoreByPoints}
            />
          )}
        </div>

        <div className='bottom'>
          <h1 className='title'>Dernières Transactions Effectué par Client</h1>
          <ClientTransactions userId={userId} />
        </div>
      </div>
    </div>
  )
}

export default Single
