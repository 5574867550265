import { timeAgo } from './service/timeFormater'

function formatDate (timestamp) {
  // Extract day, month, and year from the date object
  const date = new Date(timestamp)
  var day = date.getDate()
  var month = date.getMonth() + 1 // Month is zero-based, so add 1
  var year = date.getFullYear()

  // Pad day and month with leading zeros if necessary
  if (day < 10) {
    day = '0' + day
  }
  if (month < 10) {
    month = '0' + month
  }

  // Format the date as DD/MM/YY
  var formattedDate = day + '/' + month + '/' + year.toString().slice(2)

  return formattedDate
}


export const userColumns = [
  { field: 'id', headerName: 'ID', width: 70 },
  {
    field: 'user',
    headerName: 'User',
    width: 230,
    renderCell: params => {
      return (
        <div className='cellWithImg'>
          <img className='cellImg' src={params.row.img} alt='avatar' />
          {params.row.username}
        </div>
      )
    }
  },
  {
    field: 'email',
    headerName: 'Email',
    width: 230
  },

  {
    field: 'age',
    headerName: 'Age',
    width: 100
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 160,
    renderCell: params => {
      return (
        <div className={`cellWithStatus ${params.row.status}`}>
          {params.row.status}
        </div>
      )
    }
  }
]

export const ReservationColumns = [
  { field: 'id', headerName: 'RES N-', width: 70 },
  {
    field: 'from',
    headerName: 'Réservation Effectué de ',
    width: 200,
    renderCell: params => {
      if (!params.row.hasOwnProperty('from'))
        return <h3 className='originateFrom'>ADMIN</h3>
      if (params.row.hasOwnProperty('from') && params.row.from == 'WEB')
        return <h3 className='originateFrom'> SITE INTERNET </h3>
      if (params.row.hasOwnProperty('from') && params.row.from == 'MOBILE')
        return <h3 className='originateFrom'>APP MOBILE</h3>
    }
  },
  {
    field: 'status',
    headerName: 'État',
    width: 150,
    renderCell: params => {
      if (params.row.hasOwnProperty('status') && params.row.status == 'SEEN')
        return <h3 className='originateFrom'>Réservation traitée</h3>
      if (params.row.hasOwnProperty('status') && params.row.status == 'NEW')
        return <h3 className='originateFrom'>Nouvelle</h3>
    }
  },
  {
    field: 'fullname',
    headerName: 'Nom Complet',
    width: 150
  },
  {
    field: 'phone',
    headerName: 'Téléphone',
    width: 150
  },
  {
    field: 'when',
    headerName: 'Pour le',
    width: 200,
    renderCell: params => {
      return <>{params.row.when}</>
    }
  },
  {
    field: 'created_at',
    headerName: 'Ajouté le',
    width: 160,
    renderCell: params => {
      return <>{timeAgo(params.row.created_at)}</>
    }
  }
]


export const TransactionsColumns = [
  { field: 'id', headerName: 'TRANS N-', width: 320 },
  {
    headerName: 'Catégorie Transaction Acheté',
    field: 'serviceCategory',
    width: 350
  },
  {
    field: 'bought',
    headerName: 'Transaction Effectué',
    width: 250
  },
  {
    field: 'transactionTime',
    headerName: 'Date Transaction ',
    width: 150,
    renderCell: params => {
      return formatDate(params.row.transactionTime)
    }
  }
]

export const ClientColumns = [
  { field: 'id', headerName: 'ID', width: 70 },
  {
    field: 'fullName',
    headerName: 'Nom Complet',
    width: 230
  },
  {
    field: 'phone',
    headerName: 'Téléphone',
    width: 230
  },
  {
    headerName: 'Status',
    field: 'status',
    width: 230
  },
  {
    field: 'last_visit',
    headerName: 'Dérniére visite',
    width: 190,
    renderCell: params => {
      return <>{timeAgo(params.row.last_visit)}</>
    }
  },
  {
    field: 'created_at',
    headerName: 'Ajouté le',
    width: 160,
    renderCell: params => {
      return <>{timeAgo(params.row.created_at)}</>
    }
  },
  {
    field: 'score',
    headerName: 'Points',
    width: 100
  }
]

//temporary data
export const userRows = [
  {
    id: 1,
    username: 'Snow',
    img: 'https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500',
    status: 'active',
    email: '1snow@gmail.com',
    age: 35
  },
  {
    id: 2,
    username: 'Jamie Lannister',
    img: 'https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500',
    email: '2snow@gmail.com',
    status: 'passive',
    age: 42
  },
  {
    id: 3,
    username: 'Lannister',
    img: 'https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500',
    email: '3snow@gmail.com',
    status: 'pending',
    age: 45
  },
  {
    id: 4,
    username: 'Stark',
    img: 'https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500',
    email: '4snow@gmail.com',
    status: 'active',
    age: 16
  },
  {
    id: 5,
    username: 'Targaryen',
    img: 'https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500',
    email: '5snow@gmail.com',
    status: 'passive',
    age: 22
  },
  {
    id: 6,
    username: 'Melisandre',
    img: 'https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500',
    email: '6snow@gmail.com',
    status: 'active',
    age: 15
  },
  {
    id: 7,
    username: 'Clifford',
    img: 'https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500',
    email: '7snow@gmail.com',
    status: 'passive',
    age: 44
  },
  {
    id: 8,
    username: 'Frances',
    img: 'https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500',
    email: '8snow@gmail.com',
    status: 'active',
    age: 36
  },
  {
    id: 9,
    username: 'Roxie',
    img: 'https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500',
    email: 'snow@gmail.com',
    status: 'pending',
    age: 65
  },
  {
    id: 10,
    username: 'Roxie',
    img: 'https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500',
    email: 'snow@gmail.com',
    status: 'active',
    age: 65
  }
]

//id,name,phone,Subject,email,message,
export const MessageColumns = [
  {
    headerName: 'Mesage envoyé par',
    field: 'name',
    width: 250
  },
  {
    field: 'phone',
    headerName: 'Téléphone',
    width: 100
  },
  {
    field: 'status',
    headerName: 'État',
    width: 90,
    renderCell: params => {
      if (params.row.hasOwnProperty('status') && params.row.status == 'SEEN')
        return <h3 className='originateFrom'>Traité</h3>
      if (params.row.hasOwnProperty('status') && params.row.status == 'NEW')
        return <h3 className='originateFrom'>Nouvelle</h3>
    }
  },
  {
    field: 'created_at',
    headerName: 'Reçu le',
    width: 200,
    renderCell: params => {
      return timeAgo(params.row.created_at)
    }
  },
  {
    field: 'subject',
    headerName: 'Sujet',
    width: 150
  },
  {
    field: 'message',
    headerName: 'Mesage',
    width: 120,
    renderCell: params => {
      let message = params.row.message || false
      if (!message) return '-'
      return message.split(' ').slice(0, 3).join(' ').concat('...')
    }
  }
]
