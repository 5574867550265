import {
  collection,
  addDoc,
  getDocs,
  query,
  where,
  doc,
  orderBy,
  setDoc,
  increment,
  getDoc,
  startAfter,
  limit
} from 'firebase/firestore/lite'

import { DB } from '../App'

export async function getMessages (options) {
  let defaults = { elementsPerPage: 10, order: { created_at: 'desc' } }

  let opts = {
    ...defaults,
    ...options
  }

  const [[key], [val]] = [Object.keys(opts.order), Object.values(opts.order)]
  const { elementsPerPage, lastVisible } = opts
  const collectionRef = collection(DB, '/Message')

  console.log(key, val)
  let q

  if (lastVisible === null) {
    q = query(collectionRef, orderBy(key, val), limit(elementsPerPage))
  } else {
    const { id } = lastVisible
    let startNextAfterDoc = await getDoc(doc(collectionRef, id))
    q = query(
      collectionRef,
      orderBy(key, val),
      startAfter(startNextAfterDoc),
      limit(elementsPerPage)
    )
  }

  try {
    const messagesSnapshot = await getDocs(q)
    console.log(messagesSnapshot)
    return messagesSnapshot.docs
  } catch (error) {
    console.log(error)
    return Promise.reject(error)
    return error
  }
}

export async function getMessagesCount () {
  const collectionRef = collection(DB, `/Message`)
  try {
    const messagesSnapshot = await getDocs(collectionRef)
    return messagesSnapshot.size
  } catch (error) {
    return Promise.reject(error)
  }
}

export async function updateMessageStatusTo (
  messageId,
  newMessageStatus = 'SEEN'
) {
  if (!messageId) {
    return Promise.reject('expecting a message id')
  }

  const messageDocRef = doc(DB, 'Message', messageId)
  return await setDoc(
    messageDocRef,
    {
      status:newMessageStatus
    },
    { merge: true }
  )
}

export async function getMessageById (messageId) {
  // check if there is no message throw an error
  if (!messageId)
    return Promise.reject(
      `get Message by Id expects messageId ${typeof messageId} passed`
    )
  const collectionRef = collection(DB, `/Message`)
  const messageDocRef = doc(collectionRef, messageId)
  const message = getDoc(messageDocRef)

  return message
}
