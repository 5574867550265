import React from 'react'
import { useEffect, useState } from 'react'
import './style.scss'
import Sidebar from '../../../components/sidebar/Sidebar'
import Navbar from '../../../components/navbar/Navbar'

import { useNavigate } from 'react-router-dom'
import { addNewReservation } from '../../../service/reservation'
import ReservationDataTable from '../../../components/datatable/ReservationDataTable.jsx'
import { getAllServices } from '../../../service/Service'

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers'

import dayjs from 'dayjs'

const AddNewReservation = () => {
  const defaults = {
    status: 'SEEN',
    fullname: 'Nom Complet',
    phone: '06',
    when: new Date(),
    at: '17:00'
  }
  const [errors, setErrors] = useState([])
  const [servicesCount, setServicesCount] = useState(1)
  const [selectedServices, setSelectedServices] = useState([])

  const [services, setServices] = useState([
    { serviceName: 'Chargement...', id: '0' }
  ])
  const navigate = useNavigate()

  const [reservation, setReservation] = useState({
    ...defaults
  })

  useEffect(() => {
    async function fetch () {
      setServices([{ serviceName: 'Chargement...', id: '0' }])
      const services = await getAllServices()
      setServices([{ serviceName: 'Service', id: '-1' }, ...services])
    }
    fetch()
  }, [])

  const isReservationValid = () => {
    let errors = []

    if (reservation.fullname == '' || reservation.fullname == 'Nom Complet') {
      errors = [...errors, 'Le nom complét est obligatoire']
    }

    if (reservation.phone == '' || reservation.phone == '06') {
      errors = [...errors, 'Le téphone est obligatoire']
    }

    const regex = /^(\+212|0)([ \-_/]*)(\d[ \-_/]*){9}$/
    if (!regex.test(reservation.phone)) {
      errors = [...errors, 'Le téphone est invalide']
    }

    // check if when is in this format 24-02-2024
    const regexWhen = /^\d{2}-\d{2}-\d{4}$/
    if (!regexWhen.test(reservation.when)) {
      errors = [...errors, 'La date est invalide']
    }

    //check if the date is in this formate 07:24'
    const regexAt = /^\d{2}:\d{2}$/
    if (!regexAt.test(reservation.at)) {
      errors = [...errors, 'L\'heure est invalide']
    }



    if (selectedServices && selectedServices.length == 0) {
      errors = [...errors, 'Aucun service selectionné']
    }

    console.log(reservation);
    

    setErrors(errors)
    return errors.length == 0
  }

  const handleAddNewReservation = async event => {
    setErrors([])

    if (!isReservationValid()) return

    const doc = {
      ...reservation,
      created_at: +new Date(),
      for: [...selectedServices]
    }
    await addNewReservation(doc)
    navigate('/reservation')
    event.preventDefault()
  }

  const handleFormChange = e => {
    const [prop, value] = [e.target.name, e.target.value]
    setReservation(prev => {
      return {
        ...prev,
        [prop]: value
      }
    })
  }

  const onChangeServices = (e, index) => {
    const selectedIndex = e.target.selectedIndex
    const value = e.target.value
    const name = e.target.options[selectedIndex].getAttribute('name')
    setSelectedServices(prev => {
      let n = [...prev]
      n[index] = {
        id: value,
        serviceName: name
      }
      return n
    })
  }

  const handleAddNewService = () => {
    setSelectedServices(prev => {
      return [
        ...prev,
        {
          id: -1,
          serviceName: '-1'
        }
      ]
    })
    setServicesCount(prev => {
      return prev + 1
    })
  }

  const deleteServiceWithIndex = index => {
    const n = [...selectedServices]
    n.pop()
    setSelectedServices(n)
    setServicesCount(prev => prev - 1)
  }

  return (
    <div className='new'>
      <Sidebar />
      <div className='newContainer'>
        <Navbar />
        <header>Ajouter une Nouvelle Résérvation</header>

        <form className='addNewReservation'>
          <div className='errorsContainer'>
            {errors.length > 0 &&
              errors.map((error, index) => {
                return (
                  <div key={index} className='error'>
                    {error}
                  </div>
                )
              })}
          </div>

          <div>
            <label>Nom Complet Client</label>
            <input
              type='text'
              name='fullname'
              placeholder={defaults.fullname}
              value={reservation.fullname}
              onChange={handleFormChange}
            />
          </div>
          <div>
            <label>Téphone</label>
            <input
              type='text'
              name='phone'
              placeholder={defaults.phone}
              value={reservation.phone}
              onChange={handleFormChange}
            />
          </div>
          <div className='datepicker'>
            <label>Rendez-vous le :</label>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                onChange={date => {
                  let formating = 'DD-MM-YYYY'
                  const o = {
                    target: {
                      name: 'when',
                      value: dayjs(date).format(formating)
                    }
                  }
                  handleFormChange(o)
                }}
              />
            </LocalizationProvider>
          </div>

          <div className='mt-5 timepicker'>
            <label>L'heure du rendez-vous</label>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimePicker
                onChange={date => {
                  const formating = 'HH:mm'
                  const o = {
                    target: {
                      name: 'at',
                      value: dayjs(date).format(formating)
                    }
                  }
                  handleFormChange(o)
                }}
                viewRenderers={{
                  hours: renderTimeViewClock,
                  minutes: renderTimeViewClock,
                  seconds: renderTimeViewClock
                }}
              />
            </LocalizationProvider>
          </div>

          <div className='AddAnotherServicer-container'>
            <button type="button" onClick={handleAddNewService}>
              Ajouter un autre service
            </button>
          </div>

          <div className='mt-5'>
            <div className='services-container'>
              {services.length &&
                Array.from({ length: servicesCount }).map((e, serviceIndex) => {
                  return (
                    <div className='service-rows'>
                      <label>{`Service ${serviceIndex + 1}`}</label>
                      <select
                        key={`service-${serviceIndex}`}
                        onChange={e => onChangeServices(e, serviceIndex)}
                      >
                        {services.map(({ serviceName, id }) => {
                          return (
                            <option key={id} name={serviceName} value={id}>
                              {serviceName}
                            </option>
                          )
                        })}
                      </select>
                      {servicesCount == serviceIndex + 1 && servicesCount != 1 && (
                        <button
                          className='deleteReservationService'
                          onClick={() => deleteServiceWithIndex(serviceIndex)}
                        >
                          X
                        </button>
                      )}
                    </div>
                  )
                })}
            </div>
          </div>

          <div className='actions'>
            <button type='button' onClick={handleAddNewReservation}>
              Ajouter Reservation
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default AddNewReservation
