import React from 'react'
// import './list.scss'
import Sidebar from '../../components/sidebar/Sidebar'
import Navbar from '../../components/navbar/Navbar'
import InboxDataTable from '../../components/datatable/inboxDataTable'

const Inbox = () => {
  return (
    <div className='list'>
      <Sidebar />
      <div className='listContainer'>
        <Navbar />
        <InboxDataTable />
      </div>
    </div>
  )
}

export default Inbox
