// import "./new.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { userStateContext } from "../../context/userStateContext";
import { addClient } from "../../service/user";

const NewClient = () => {
    const [clientData, setClientData] = useState({
        fullName: "",
        phone: "",
        created_at: +new Date(),
    });
    const navigate = useNavigate();

    const { user } = useContext(userStateContext);

    useEffect(() => {
        if (null == user || false == user.loggedIn) {
            navigate('/login')
        }
    }, [user]);

    function clearInputs() {
        setClientData({
            phone: "",
            fullName: ""
        });
    }

    const AddNewClient = async (e) => {
        e.preventDefault();
        try {
            if (clientData.fullName.replace(/\s+/, '') == "")
                throw new Error('Le nom complet ne peut pas être vide');
            if (clientData.phone.replace(/\s+/, '') == "")
                throw new Error('Le numéro de téléphone ne peut pas être vide');
            if (clientData.phone.toString().match(/^(\+212|0)([ \-_/]*)(\d[ \-_/]*){9}$/) === null)
                throw new Error('Le numéro de téléphone doit être un numéro de téléphone marocain valide')
            await addClient(clientData);
            console.log('client added')
            clearInputs();
        } catch (error) {
            console.log(error);
        }
        // check if the name and phone are not empty

    }


    const onClientDataChange = (input) => {
        return (e) => {
            setClientData({
                ...clientData,
                [input]: e.target.value,
                created_at: +new Date(),
            });
        }
    }

    return (
        <div className="new">
            <Sidebar />

            <div className="newContainer">
                <Navbar />
                <div className="top">
                    <h1>Ajouter Un Nouveau Client</h1>
                </div>

                <div className="formContainer">
                    <form className="addNewCategory addNewUser">
                        <div className="row">
                            <input type="text" value={clientData.fullName} className="formInput" name="clientFullName" placeholder="Client Nom et Prénom :"
                                onChange={onClientDataChange('fullName')}
                            />
                        </div>
                        <div className="row">
                            <input type="tel" value={clientData.phone} className="formInput" name="phone" placeholder="Téléphone : "
                                onChange={onClientDataChange('phone')}
                            />
                        </div>
                        <button type="button" onClick={AddNewClient}>Ajouter Nouveau Client</button>
                    </form>
                </div>
            </div>
        </div>
    )
}


export default NewClient