import { useState, useEffect } from 'react'
import { getMessages } from '../service/Message'

export const useMessages = options => {
  const { page, lastVisible, elementsPerPage } = options
  const [messages, setMessages] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      setMessages([])

      try {
        let response = await getMessages({ lastVisible, elementsPerPage })
        // get data
        let data = response.map(client => {
          return {
            id: client.id,
            ...client.data()
          }
        })
        console.log(data);
        setMessages(data)
        console.log('GOT DATA');
        console.log(data);
      } catch (error) {
        console.log(error)
        setError(error)
        setMessages([])
      } finally {
        setLoading(false)
      }
    }

    fetchData()
  }, [page])

  return [messages, loading, error]
}
