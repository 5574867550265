import {
  collection,
  addDoc,
  getDocs,
  query,
  where,
  doc,
  updateDoc,
  limit,
  orderBy,
  startAt,
  endAt,
  increment
} from 'firebase/firestore/lite'
import { DB } from '../App'
import { updateLastVisite } from './user'

/**
 *
 * @param {*} userId
 * @returns Promise
 */
export async function getSubscriptionsByClientId (userId) {
  if (!userId)
    return Promise.reject(
      new Error(`can't get user subscription userId ${typeof userId}`)
    )
  const collectionRef = collection(DB, `/Client/${userId}/Subscription`)
  return getDocs(collectionRef)
}

export async function AttachSubscriptionToUserWithId (userId, doc) {
  if (!userId)
    return Promise.reject(
      new Error(
        `can't attach subscription to user expecting userId got ${typeof userId}`
      )
    )
  await updateLastVisite(userId)
  return addDoc(collection(DB, `/Client/${userId}/Subscription`), doc)
}

export async function IncrementSessionPreformed (userId, subscriptionId) {
  if (!userId)
    return Promise.reject(
      new Error(`can't increment session preformed userId ${typeof userId}`)
    )
  if (!subscriptionId)
    return Promise.reject(
      new Error(
        `can't increment session preformed subscriptionId ${typeof subscriptionId}`
      )
    )
  console.log(`/Client/${userId}/Subscription/${subscriptionId}`)
  const docRef = doc(DB, `/Client/${userId}/Subscription/${subscriptionId}`)
  //   await updateLastVisite(userId);
  const updatedDoc = await updateDoc(docRef, {
    sessionPerformed: increment(1)
  });

}
