import "./Add.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { Alarm, DriveFolderUploadOutlined } from "@mui/icons-material";
import { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { userStateContext } from "../../context/userStateContext";
import { getServiceCatogories } from "../../service/category";
import { addService,addServiceWithMultipleSessions} from "../../service/Service";




const AddNewService = () => {
    const defaultServiceTypes = [{ id: "SIMPLE", name: "Service Simple", selected: true }, { id: "MULTIPLE_SESSIONS", name: "Service Avec Plusieurs Sessions", selected: false }];
    const [errorMessage, setErrorMessage] = useState('');

    const [categories, setCategories] = useState([]);
    const [serviceName, setServiceName] = useState('');
    const [pointPerSerice, setPointPerService] = useState("");
    const [selectedCategoryId, setSelectedCatogoryId] = useState(-1);
    const [servicePrice, setServicePrice] = useState("");
    const [serviceTypes, setServiceTypes] = useState(defaultServiceTypes);
    const [sessionsCount, setSessionsCount] = useState(1);
    const navigate = useNavigate();

    const { user } = useContext(userStateContext);

    useEffect(() => {
        if (null == user || false == user.loggedIn) {
            navigate('/login')
        }
    }, [user]);


    useEffect(() => {
        async function init() {
            const data = await getServiceCatogories();
            console.log(data);
            setCategories(data);
        }
        init();
        // get all service categories 
        //populate the service categories
    }, [])

    useEffect(() => {
        if (sessionsCount <= 0) {
            setSessionsCount(1);
        }
    }, [sessionsCount])


    const selected_service_type = useMemo(() => {
        let selectedService = serviceTypes.filter((service) => service.selected === true);
        if (selectedService.length === 1) {
            return selectedService[0]['id'];
        }
        return false;
    }, [serviceTypes])



    const formIsClean = () => {
        if (pointPerSerice == "" || serviceName == "" || selectedCategoryId == -1 || servicePrice == "")
            return false;
        else
            return true;
    }


    const handleAddServceForm = async (e) => {
        e.preventDefault();
        // TODO add input validation 
        if (!formIsClean()) {
            setErrorMessage('Veuillez vérifier que tout les champs du formulaire sont bien rempli');
            return
        }
        setErrorMessage('');
        const doc = {
            serviceName,
            servicePrice: parseInt(servicePrice),
            pointPerSerice: parseInt(pointPerSerice),
            selectedCategoryId,
            created_at: +new Date(),
        }

        if ("MULTIPLE_SESSIONS" === selected_service_type) {
            let ServiceWithMultipleSessionsDoc = {
                ...doc,
                sessionsCount,
            }
            await addServiceWithMultipleSessions(ServiceWithMultipleSessionsDoc)    
        } else {
            await addService(doc)
        }

        clearInputFields();
    }


    const clearInputFields = () => {
        setServiceName("")
        setServicePrice("")
        setSelectedCatogoryId(-1)
        setPointPerService("")
        setSessionsCount(1);
    }


    const handleSelectCategoryId = (e) => {
        const categoryId = e.target.value;
        setSelectedCatogoryId(categoryId);
    }

    const toggleServiceType = (id) => {

        const newList = serviceTypes.map((service) => {
            if (service.id === id) {
                return ({
                    ...service,
                    selected: !service.selected
                });
            }
            return ({
                ...service,
                selected: false
            })
        });

        // Check if No Service is selected and set the default service to defaultServiceTypes

        let selectedType = newList.filter((service) => service.selected)
        if (selectedType.length === 0) {
            setServiceTypes(defaultServiceTypes);
        } else {
            setServiceTypes(newList);
        }
    }

    return (
        <div className="new">
            <Sidebar />

            <div className="newContainer">
                <Navbar />
                <div className="formContainer">
                    <div className="formActionsConatiner">
                        {/* <button className="anotherCategory" onClick={incrementCategoryCount}>Une Autre</button> */}
                    </div>
                    <form className="addNewCategory addNewService">
                        <div className="errorContainer">
                            <p>
                                {errorMessage}
                            </p>
                        </div>

                        <div className="servicetypeContainer">
                            {
                                serviceTypes.map((service) => {
                                    return (
                                        <div className={`servicetype ${service.selected ? 'selected' : ''}`} onClick={() => toggleServiceType(service.id)}>
                                            <h4>{service.name}</h4>
                                        </div>
                                    )
                                })
                            }
                        </div>

                        <div>
                            <label>Categorie Du service</label>
                            <select value={selectedCategoryId} onChange={handleSelectCategoryId}>
                                <option defaultValue> Catégorie du service</option>
                                {

                                    categories.map(({ name, id }) => {
                                        return (
                                            <option key={id} value={id} onChange={(() => setSelectedCatogoryId(id))}> {name}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <div>
                            <label>Service :</label>
                            <input type="text" placeholder={"Service"} name="serviceName" value={serviceName} onChange={(e) => setServiceName(e.target.value)} />
                        </div>
                        <div>
                            <label>Prix en Dh</label>
                            <input type="text" placeholder={"Prix en DH"} name="price" value={servicePrice} onChange={(e) => setServicePrice(e.target.value)} />
                        </div>

                        <div>
                            <label>Points/Service</label>
                            <input type="number" placeholder={"points"} step={5} name="points" value={pointPerSerice} onChange={(e) => setPointPerService(e.target.value)} />
                        </div>

                        {
                            (selected_service_type && selected_service_type === "MULTIPLE_SESSIONS") && (<div>
                                <div>
                                    <label>Sessions</label>
                                    <input type="number" placeholder={"Sessions"} step={1} name="sessions" value={sessionsCount} onChange={(e) => setSessionsCount(e.target.value)} />
                                </div>
                            </div>)
                        }

                        <button type="button" onClick={handleAddServceForm}>Ajouter Service</button>
                    </form>
                </div>
            </div >
        </div >
    )
}

export default AddNewService