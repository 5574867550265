import "./table.scss"
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { CircularProgress } from "@mui/material";
import { ErrorOutlined } from "@mui/icons-material";

import Paper from '@mui/material/Paper';
import { useEffect, useState } from "react";
import { getClients } from "../../service/user";
import { timeAgo, toLocaleDateString } from "../../service/timeFormater";
import { Link } from "react-router-dom";



const List = () => {
  const [loading, setLoading] = useState(true);
  const [clients, setClients] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        let clientsDocs = await getClients({limitSize: 5,orderByValue:'last_visit',lastVisible:null});

        // extract id and data fom docs
        clientsDocs = clientsDocs.map((doc) => {
          return { id: doc.id, ...doc.data() }
        })
        setLoading(false);
        setClients(clientsDocs);
      } catch (error) {
        console.log(error);
        setLoading(false)
        setError('Échec de récupération des utilisateurs.')
      }
    })()
  }, [])


  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center',paddingBottom: '10px' }} >
        {!loading && error && <>
          <ErrorOutlined color="primary" />
          <h3>{error}</h3>
        </>
        }

        {loading && <CircularProgress size={50} color="primary" style={{ alignSelf: 'center', width: "40px", height: "40px", marginLeft: 'auto', marginRight: 'auto' }} />}

      </div>

      {!loading && !error && <TableContainer component={Paper} className="table" style={{ marginTop: "-10px" }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className="tableCell">Nom </TableCell>
              <TableCell className="tableCell">Téléphone</TableCell>
              <TableCell className="tableCell">Création</TableCell>
              <TableCell className="tableCell">Dérniere Visite</TableCell>
              <TableCell className="tableCell">Points</TableCell>
              <TableCell className="tableCell">Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {clients.map((row) => (
              <TableRow
                key={row.id} >
                <TableCell className="tableCell">{row.fullName}</TableCell>
                <TableCell className="tableCell">{row.phone}</TableCell>
                <TableCell className="tableCell">{timeAgo(row.created_at)}</TableCell>
                <TableCell className="tableCell">{timeAgo(row['last_visit'])}</TableCell>
                <TableCell className="tableCell">{row['score']}</TableCell>
                <TableCell className="tableCell">
                  <span className={`status ${row.id}`}>
                    <Link to={`/users/${row.id}`}>Visualiser</Link>
                  </span>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>}
    </>
  )
}

export default List
