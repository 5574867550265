import { useEffect, useState } from 'react'
import {
  getSubscriptionsByClientId,
  IncrementSessionPreformed
} from '../../service/Subscription'

import {IncrementClientScore,updateLastVisite} from '../../service/user';

import './Subscriptions.css'

export default function Subscriptions ({ user , onSubscriptionFinished}) {
  const [subscriptions, setSubscriptions] = useState([])
  const [loading, setLoading] = useState(true)

  const onSessionsPreformed = async subscriptionId => {
    try {
      await IncrementSessionPreformed(user, subscriptionId)
      updateLastVisite(user);
      let updateScore = false;
      let incrementBy = 0;
      // loop over subscriptions and update the sessionPerformed
      const updatedSubscriptions = subscriptions.map(sub => {
        if ( sub.subcriptionId === subscriptionId && sub.sessionPerformed < sub.sessionsCount) {
          sub.sessionPerformed += 1
          // after we increment sessions preformed we check if the service is done 
          if(sub.sessionPerformed == sub.sessionsCount){
            updateScore = true;
            incrementBy = sub.pointPerSerice;
          }
          return sub
        } else {
          return sub
        }
      })
      setSubscriptions(updatedSubscriptions)
      // increment user score
      if(updateScore){
        IncrementClientScore(user,incrementBy);
        onSubscriptionFinished(incrementBy)
      }
    } catch (error) {
      console.log('error incremeting sessions')
      console.log(error)
    }
  }

  // when the user changes fetch the list of subscriptions
  useEffect(() => {
    async function fetchSubscriptions () {
      const subsc = await getSubscriptionsByClientId(user)
      const subsList = subsc.docs.map(sub => {
        return {
          subcriptionId: sub.id,
          ...sub.data()
        }
      })

      setSubscriptions(subsList)
      setLoading(false)
    }

    fetchSubscriptions()
  }, [user])

  return (
    <div className='bottom'>
      <div>
        <div className='subscriptionList'>
          {subscriptions.map((subscription, index) => (
            <SubscriptionItem
              key={index}
              subscription={subscription}
              onSessionsPreformed={onSessionsPreformed}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

function SubscriptionItem (props) {
  console.log(props)
  const { id } = props.subscription // catergory of the service
  const { subcriptionId } = props.subscription
  const widthConatainer = 700
  const widthCell = widthConatainer / props.subscription.sessionsCount

  const handleIncrementSessionsPreformed = async () => {
    // await incrementSessionsPreformed(id)
    props.onSessionsPreformed(subcriptionId)
  }

  return (
    <div className='subscriptionItem'>
      <div className='subscription-info'>
        <h2>{props.subscription.serviceName}</h2>
        <h3>
          {props.subscription.sessionPerformed} /{' '}
          {props.subscription.sessionsCount}{' '}
        </h3>
      </div>
      <div className='session-container-with-action'>
        <div className='sessions-renderer' style={{ width: widthConatainer }}>
          {Array.from({ length: props.subscription.sessionsCount }).map(
            (_, index) => {
              if (props.subscription.sessionPerformed > index) {
                return (
                  <div
                    style={{ width: widthCell }}
                    className='session session-completed item '
                  />
                )
              } else {
                return (
                  <div
                    style={{ width: widthCell }}
                    className='session session-remaining item key'
                  />
                )
              }
            }
          )}
        </div>
        <div className='incrementer'>
          <button
            className='incrementer-button'
            onClick={handleIncrementSessionsPreformed}
          >
            +
          </button>
        </div>
      </div>
    </div>
  )
}
