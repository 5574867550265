import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom'
import './widget.scss'
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp'
import PersonOutlineOutlined from '@mui/icons-material/PersonOutlineOutlined'
import AccountBalanceWalletOutLinedIcon from '@mui/icons-material/AccountBalanceOutlined'
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined'
import EventIcon from '@mui/icons-material/Event'
import MonetizationOnOutlined from '@mui/icons-material/MonetizationOnOutlined'
import RefreshIcon from '@mui/icons-material/Refresh'
import { Add } from '@mui/icons-material'

import { getNewOrdersCount } from '../../service/order'
import { getClientsCount } from '../../service/user'
import { getNewReservationsCount } from '../../service/reservation'

const getLinktoNewForType = type => {
  const links = {
    user: '/users/new',
    reservation: '/reservation/new'
  }

  return links[type]
}

const useFetchWithType = type => {
  const [amount, setAmount] = useState(0)
  const [isFetching, setIsFetching] = useState(true)
  const [fetchingFailed, setFetchingFailed] = useState(false)

  const types = {
    user: getClientsCount,
    order: getNewOrdersCount,
    reservation: getNewReservationsCount
  }

  const reloadType = async type => {
    setIsFetching(true)
    const data = await types[type]()
    setFetchingFailed(false)
    setIsFetching(false)
    setAmount(data)
  }

  useEffect(() => {
    ;(async () => {
      try {
        const data = await types[type]()
        console.log('FETCHING FOR TYPE', type)
        console.log('response for type', data)
        setFetchingFailed(false)
        setIsFetching(false)
        setAmount(data)
      } catch (error) {
        console.log(error)
        setFetchingFailed()
      }
    })()
  }, [type])

  return [amount, isFetching, fetchingFailed, reloadType]
}

const Widget = ({ type }) => {
  let data
  const [amount, isFetching, fetchingFailed, reloadType] =
    useFetchWithType(type)
  const navigate = useNavigate()
  console.log('calling Widget with type', type)

  const handleReloadButton = () => {
    reloadType(type)
  }

  const handleNavigateToNew = () => {
    let np = getLinktoNewForType(type)
    navigate(np)
  }

  switch (type) {
    case 'user':
      data = {
        title: 'Utilisateurs',
        isMoney: false,
        uri: '/users',
        link: 'Tous les utilisateurs ',
        icon: (
          <PersonOutlineOutlined
            className='icon'
            style={{
              color: 'crimson',
              backgroundColor: ' rgba(218,165,32,0.2)'
            }}
          />
        )
      }
      break
    case 'order':
      data = {
        title: 'COMMANDES',
        isMoney: false,
        uri: '/orders',
        link: 'Toutes les commandes',
        icon: (
          <ShoppingCartOutlinedIcon
            className='icon'
            style={{
              color: 'goldenrod',
              backgroundColor: ' rgba(218,165,32,0.2)'
            }}
          />
        )
      }
      break
    case 'reservation':
      data = {
        title: 'Nouvelles Réservations',
        isMoney: false,
        uri: '/reservation',
        link: 'Toutes les Réservations',
        icon: (
          <EventIcon
            className='icon'
            style={{
              color: 'goldenrod',
              backgroundColor: ' rgba(218,165,32,0.2)'
            }}
          />
        )
      }
      break
    case 'earning':
      data = {
        title: 'EARNINGS',
        isMoney: true,
        link: 'View net earnings',
        icon: (
          <MonetizationOnOutlined
            className='icon'
            style={{
              color: 'green',
              backgroundColor: ' rgba(0,128,0,0.2)'
            }}
          />
        )
      }
      break
    case 'balance':
      data = {
        title: 'BALANCE',
        isMoney: true,
        link: 'See details',
        icon: (
          <AccountBalanceWalletOutLinedIcon
            className='icon'
            style={{
              color: 'purple',
              backgroundColor: ' rgba(128,0,128,0.2)'
            }}
          />
        )
      }
      break
    default:
      break
  }

  return (
    <div className='widget'>
      <div className='left'>
        <span className='title'>{data.title}</span>
        <span className='counter'>
          {(() => {
            if (isFetching) return 'CHARGEMENT...'
            else if (false == isFetching && true == fetchingFailed)
              return 'Erreur de récupération'
            else {
              return (
                <Link className={'amountLink'} to={data.uri}>
                  {amount}
                </Link>
              )
            }
          })()}
          {/* {data.isMoney && "DHS"} */}
        </span>
        {type == 'user' || type == 'reservation' ? (
          <Link to={data.uri} className={'linkInWidget'}>
            <span className='link'>{data.link}</span>
          </Link>
        ) : (
          <span className='link'>{data.link}</span>
        )}
      </div>
      <div className='right' style={{ position: 'relative' }}>
        <button className='reloadButton' onClick={handleReloadButton}>
          <RefreshIcon />
        </button>

        <button className='addButton' onClick={handleNavigateToNew}>
          <Add />
        </button>
      </div>
    </div>
  )
}

export default Widget
