import {
  collection,
  addDoc,
  getDocs,
  query,
  where,
  doc,
  updateDoc,
  limit,
  orderBy,
  startAt,
  endAt
} from 'firebase/firestore/lite'
import { DB } from '../App'

export const recordPurchase = purchaseDoc => {
  const collectionRef = collection(DB, `/Purchase`)
  return addDoc(collectionRef, {
    ...purchaseDoc
  })
}

export async function getPurchasesCountByClientId (userId) {
  if (userId === null || userId === undefined) {
    return Promise.reject('userId is null or undefined')
  }
  const collectionRef = collection(DB, `/Client/${userId}/Purchase`)
  try {
    const purchasesSnapshot = await getDocs(collectionRef)
    return purchasesSnapshot.size
  } catch (error) {
    console.log(error)
    return Promise.reject('error getting purchases count')
  }
}
