import {
  collection,
  addDoc,
  getDocs,
  query,
  where,
  doc,
  orderBy,
  setDoc,
  increment,
  getDoc,
  startAfter,
  limit,
  deleteDoc
} from 'firebase/firestore/lite'

import { DB } from '../App'

export function getClientDataById (userId) {
  const collectionRef = collection(DB, `/Client`)
  const clientDocRef = doc(collectionRef, userId)
  return getDoc(clientDocRef)
}

export async function getClientsCount () {
  const collectionRef = collection(DB, `/Client`)
  try {
    const clientsSnapshot = await getDocs(collectionRef)
    return clientsSnapshot.size
  } catch (error) {
    console.log(error)
    return -1
  }
}

export async function getClients (
  options = { limitSize: 10, lastVisible: null, orderByValue: 'created_at' }
) {
  let defaults = {
    limitSize: 10,
    lastVisible: null,
    orderByValue: 'created_at'
  }
  let opts = {
    ...defaults,
    ...options
  }
  const { limitSize, offset, lastVisible, orderByValue } = opts
  const collectionRef = collection(DB, `/Client`)
  let q

  if (lastVisible !== null) {
    let startAF = await getDoc(doc(collectionRef, lastVisible['id']))
    q = query(
      collectionRef,
      orderBy(orderByValue, 'desc'),
      startAfter(startAF),
      limit(limitSize)
    )
  } else {
    q = query(collectionRef, orderBy(orderByValue, 'desc'), limit(limitSize))
  }
  try {
    const clientsSnapshot = await getDocs(q)
    let docs = clientsSnapshot.docs
    return docs
  } catch (error) {
    console.log(error)
    return Promise.reject(error)
    // return error
  }
}

export async function addClient (clientData) {
  const collectionRef = collection(DB, `/Client`)
  return addDoc(collectionRef, {
    ...clientData,
    score: 0,
    last_visit: +new Date()
  })
}

export async function CustomerWithIdBuyedService (customerId, serviceData) {
  const collectionRef = collection(DB, `/Purchase`)
  return addDoc(collectionRef, {
    customerId,
    ...serviceData,
    created_at: +new Date()
  })
}

export async function LookupClientByPhone (phoneNumber, limitCount = 10) {
  const collectionRef = collection(DB, `/Client`)
  const q = query(
    collectionRef,
    orderBy('phone', 'desc'),
    where('phone', '>=', phoneNumber),
    where('phone', '<=', phoneNumber + '\uf8ff'),
    limit(limitCount)
  )
  return getDocs(q)
}

export async function updateLastVisite (userId) {
  if (!userId) {
    throw new Error('expexting a User id')
  }
  const clientDocRef = doc(DB, 'Client', userId)
  return await setDoc(
    clientDocRef,
    {
      last_visit: +new Date()
    },
    { merge: true }
  )
}

export async function RecordPurchaseForUser (userId, salesData) {
  if (!userId)
    return Promise.reject(
      new Error("User Id is missing can't attach sale to user wihtout id")
    )
  await updateLastVisite(userId)
  const collectionRef = collection(DB, `/Client/${userId}/Purchase`)
  return addDoc(collectionRef, salesData)
}

export async function IncrementClientScore (userId, points) {
  if (!userId)
    return Promise.reject(
      new Error("User Id is missing can't attach sale to user wihtout id")
    )
  const clientDocRef = doc(DB, 'Client', userId)
  return await setDoc(
    clientDocRef,
    {
      score: increment(points)
    },
    { merge: true }
  )
}

/**
 * return the services bought by a specific user
 */
export async function GetPurchasesForUser (options) {
  let defaults = {
    userId: null,
    elementsPerPage: 10,
    order: { transactionTime: 'desc' }
  }

  let opts = {
    ...defaults,
    ...options
  }
  const { userId } = opts
  if (!userId || userId === null) {
    throw new Error(
      "User Id is missing can't get purchases for user wihtout id"
    )
  }

  const [[key], [val]] = [Object.keys(opts.order), Object.values(opts.order)]
  const { elementsPerPage, lastVisible } = opts
  const collectionRef = collection(DB, `/Client/${userId}/Purchase`)
  console.log(key, val)

  let q

  if (lastVisible === null) {
    // we get from 0 - elementsPerPage
    q = query(collectionRef, orderBy(key, val), limit(elementsPerPage))
  } else {
    // we get from lastVisible - elementsPerPage
    const { id } = lastVisible
    let startNextAfterDoc = await getDoc(doc(collectionRef, id))
    q = query(
      collectionRef,
      orderBy(key, val),
      startAfter(startNextAfterDoc),
      limit(elementsPerPage)
    )
  }

  try {
    const clientsSnapshot = await getDocs(q)
    return clientsSnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }))
  } catch (error) {
    console.log(error)
    return Promise.reject(error)
  }
}

export async function deleteClientById (clientId) {
  if (!clientId) throw new Error('expected a client id')

  const collectionRef = collection(DB, `/Client`)
  const cleintDocRef = doc(collectionRef, clientId)
  const deletedDoc = await deleteDoc(cleintDocRef)
}

export async function decrementScoreByPoints (clientId, decrementBy) {
  if (!clientId){
      throw new Error("User Id is missing can't attach sale to user wihtout id")
  }

  const decrementScoreBy = parseInt(decrementBy) * -1;
  const clientDocRef = doc(DB, 'Client', clientId)
  return await setDoc(
    clientDocRef,
    {
      score: increment(decrementScoreBy)
    },
    { merge: true }
  )
}
