import { collection, addDoc, getDocs, query, where, doc, updateDoc } from 'firebase/firestore/lite';
import { DB } from "../App";


export const getNewOrdersCount = () => {
    const orderRef = collection(DB, `/Order`);
    return new Promise(async (resolve, reject) => {
        try {

            const q = query(orderRef, where("status", "==", "NEW"));
            const ordersSnapshot = await getDocs(q);
            resolve(ordersSnapshot.size);
            // const requests = ordersSnapshot.docs.map(doc => ({
            // 	id: doc.id,
            // 	...doc.data(),
            // }))
            // resolve(requests);
        }
        catch (error) {
            reject(error)
        }
    })

}