import React from 'react'
import { useEffect, useState } from 'react'
import './style.scss'
import Sidebar from '../../../components/sidebar/Sidebar'
import Navbar from '../../../components/navbar/Navbar'

import ReservationDataTable from '../../../components/datatable/ReservationDataTable.jsx'

const ListReservations = () => {
  return (
    <div className='list'>
      <Sidebar />
      <div className='listContainer'>
        <Navbar />
        {/*!loading && error && (
          <div>
            <p>Erreur lors de la recuperation des clients...</p>
          </div>
        )*/}

        <ReservationDataTable />
      </div>
    </div>
  )
}

export default ListReservations
