/**
 * PAGES
 */
import "./style/app.scss";
import Home from './pages/home/Home'
import Login from './pages/login/Login'
// import List from "./pages/list/List";
import Single from './pages/single/Single'
import NewClient from './pages/Client/New'
import NewPurchase from './pages/Purchase/New'

import AddNewService from './pages/service/Add'
import ListServices from './pages/service/List'

import ListClients from './pages/Client/List/List'
import ListReservations from './pages/Reservation/List'
import AddNewReservation from './pages/Reservation/New'
import Inbox from './pages/inbox/Inbox'
import InboxMessage from './pages/inbox/Show'
import ShowReservationDetails from './pages/Reservation/Show/'


import ListCategories from "./pages/Category/List";


/**
 * END PAGES
 */

/**
 * SERVICE
 */

import NewService from './components/service/New'

import New from './pages/new/New'

import ListGifts from "./pages/Gift/List";
import AddNewGift from './pages/Gift/add'

import { productInputs, userInputs } from './formSource'
import './style/dark.scss'
import { useContext } from 'react'
import { DarkModeContext } from './context/darkModeContext'
// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import { getFirestore } from 'firebase/firestore/lite'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import { BrowserRouter, Routes, Route } from 'react-router-dom'

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyDi4REHsL2o0SA1gTZMAyEtvpCkCeFghCU',
  authDomain: 'viola-beaut-center.firebaseapp.com',
  projectId: 'viola-beaut-center',
  storageBucket: 'viola-beaut-center.appspot.com',
  messagingSenderId: '804009401798',
  appId: '1:804009401798:web:ce7aebb68339d22ab9e6c7',
  measurementId: 'G-6EBERL8F6K'
}

// Initialize Firebase
export const firebase = initializeApp(firebaseConfig)
export const DB = getFirestore(firebase)
const analytics = getAnalytics(firebase)

function App () {
  const { darkMode } = useContext(DarkModeContext)

  return (
    <div className={darkMode ? 'app dark' : 'app'}>
      <BrowserRouter>
        <Routes>
          <Route path='login' element={<Login />} />

          <Route path='/'>
            <Route path='/' element={<Home />} />

            <Route path='inbox'>
              <Route path='/inbox' element={<Inbox />} />
              <Route path=':messageId' element={<InboxMessage />} />
            </Route>

            <Route path='category'>
              <Route index element={<ListCategories />} />
              <Route
                path='add'
                element={<New inputs={userInputs} title='Add New User' />}
              />
            </Route>

            <Route path='gift'>
              <Route index element={<ListGifts />} />
              <Route path='add' element={<AddNewGift />} />
            </Route>

            <Route path='service'>
              <Route index element={<ListServices />} />
              <Route path='add' element={<AddNewService />} />
            </Route>

            <Route path='purchase'>
              <Route path='new' element={<NewPurchase />} />
            </Route>

            <Route path='users'>
              <Route index element={<ListClients />} />
              <Route path=':userId' element={<Single />} />
              <Route path='new' element={<NewClient />} />
            </Route>

            {/* <Route path='products'>
              {/* <Route index element={<List />} /> */}
            {/*<Route path=':productId' element={<Single />} />
              <Route
                path='new'
                element={<New inputs={productInputs} title='Add New Product' />}
              />
            </Route> */}

            <Route path='reservation'>
              <Route index element={<ListReservations />} />
              <Route path='new' element={<AddNewReservation />} />
              <Route
                path=':reservationId'
                element={<ShowReservationDetails />}
              />
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  )
}

export default App
