import './new.scss'
import Sidebar from '../../components/sidebar/Sidebar'
import Navbar from '../../components/navbar/Navbar'
import { Alarm, DriveFolderUploadOutlined } from '@mui/icons-material'
import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { userStateContext } from '../../context/userStateContext'
import { addServiceCategory } from '../../service/category'
import {addGift} from "../../service/Gift";

const AddNewGift = () => {
  const [giftsCount, setGiftsCount] = useState(1)
  const [categoryCount, setCategorieCount] = useState(1)
  const [gifts, setGifts] = useState([
    {
      name: 'Nouveau Cadeau',
      points: 100
    }
  ])
  const [categories, setCategories] = useState(['Nouvelle Category'])
  const navigate = useNavigate()

  const { user } = useContext(userStateContext)

  useEffect(() => {
    if (null == user || false == user.loggedIn) {
      navigate('/login')
    }
  }, [user])

  useEffect(() => {
    console.log(gifts)
  }, [giftsCount])

  const incrementGiftCount = () => {
    setGiftsCount(count => {
      return count + 1
    })
    setGifts(gifts => {
      return [
        ...gifts,
        {
          name: 'Nouveau Cadeau',
          points: 10
        }
      ]
    })
  }

  const editInput = (index, key) => {
    return function (e) {
      console.log(e.target.value)
      const oldArray = [...gifts]
      oldArray[index][key] = e.target.value
      setGifts(oldArray)
    }
  }

  const clearInputs = () => {
    setGiftsCount(1);
    setGifts([
      {
        name: '',
        points: 100
      }
    ])
  }

  const addGifts = async e => {
    e.preventDefault()
    try {
      for (const gift of gifts) {
        if (
          gift['name'] == 'Nouveau Cadeau' ||
          gift['name'] == '' ||
          gift['name'].trim() == ''
        ) {
          throw new Error('Element is empty')
        }
        addGift({
          name:gift['name'],
          points:parseInt(gift["points"])
        });

      }
      clearInputs()
    } catch (error) {
      console.log(error)
    }
  }

  const addCategories = async e => {
    e.preventDefault()

    try {
      for (const element of categories) {
        if (
          element == 'Nouvelle Category' ||
          element == '' ||
          element.trim() == ''
        ) {
          throw new Error('Element is empty')
        }
        await addServiceCategory(element)
      }
      clearInputs()
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div className='new'>
      <Sidebar />

      <div className='newContainer'>
        <Navbar />
        <div className='headerTitle'>
          <h1>Ajouter Un Nouveau Cadeau</h1>
        </div>

        <div className='formContainer'>
          <div className='formActionsConatiner'>
            <button className='anotherCategory' onClick={incrementGiftCount}>
              Ajouter Un Autre cadeau
            </button>
          </div>
          <form className='addNewCategory'>
            {Array.from({ length: giftsCount }).map((_element, index) => {
              return (
                <div
                  key={index}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    paddingRight: 20,
                    paddingBottom: 20,
                    paddingTop: index == 0 ? 0 : 20,
                    borderBottom: '1px solid #000'
                  }}
                >
                  <label>Cadeau {index + 1}</label>
                  <input
                    placeholder={'Nouveau cadeau'}
                    type='text'
                    name='giftName'
                    value={gifts[index]['name']}
                    onChange={editInput(index, 'name')}
                  />
                  <label>Points {index + 1}</label>
                  <input
                    type='number'
                    name='points'
                    value={gifts[index]['points']}
                    onChange={editInput(index, 'points')}
                  />
                </div>
              )
            })}
            <button type='button' onClick={addGifts}>
              Ajouter
            </button>
          </form>
        </div>
      </div>
    </div>
  )
}

export default AddNewGift
